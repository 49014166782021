<div class="new-model-component">
  <h1>Bérlések</h1>
  <mat-icon *ngIf="updateRentPage"  class="update-car" (click)="updateRentPage = false;  getLists()">undo</mat-icon>

  <section *ngIf="!updateRentPage" class="example-section">
    <mat-checkbox class="example-margin" [(ngModel)]="showFinishedRents" (ngModelChange)="showAllRents = null" routerLink="/berlesek/lezart-vagy-nem-lezart">Nem
      lezárt bérlésék
    </mat-checkbox>
  </section>

  <section *ngIf="!updateRentPage" class="example-section">
    <mat-checkbox class="example-margin" [(ngModel)]="showAllRents" (change)="showFinishedRents = null" routerLink="/berlesek/osszes">Összes bérlés
    </mat-checkbox>
  </section>

  <app-rents-list *ngIf="showAllRents"></app-rents-list>
  <app-finished-rents *ngIf="!showFinishedRents && !showAllRents"></app-finished-rents>
  <app-unfinished-rents *ngIf="showFinishedRents && !showAllRents"></app-unfinished-rents>

  <app-add-rent [rent]="rentToUpdate" [update]="true" *ngIf="updateRentPage"></app-add-rent>

</div>
