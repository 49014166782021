import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AppService} from '../app.service';
import Swal from 'sweetalert2';
import {CarsComponent} from '../cars/cars.component';
import {CarsFreeComponent} from '../cars-free/cars-free.component';

export interface RentData {
  id: string;
  rent_start: string;
  rent_end: string;
  renter_id: string;
  deposit: string;
  kmh_state_before: string;
  kmh_state_after: string;
  takeaway_start: string;
  takeaway_end: string;
  finished: string;
}

@Component({
  selector: 'app-rents-by-car',
  templateUrl: './rents-by-car.component.html',
  styleUrls: ['./rents-by-car.component.css']
})
export class RentsByCarComponent implements OnInit {

  rentToUpdate: any;
  updateRentPage = false;
  rents: any;
  showTable: boolean = true;
  @Input() plate = '';

  displayedColumns: string[] = ['id', 'rent_start', 'rent_end', 'renter_id', 'deposit', 'kmh_state_before', 'kmh_state_after', 'takeaway_start', 'takeaway_end'];
  dataSource: MatTableDataSource<RentData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private service: AppService,
    private carsList: CarsComponent,
    private freeCarsList: CarsFreeComponent
  ) {
  }

  ngOnInit(): void {
    this.showTable = true;
    if (this.plate) {
      this.getRentsByPlate(this.plate);
    } else {
    }
  }

  getRentsByPlate(plate): void {
    this.service.getRentsByPlate(plate).subscribe(rentsByPlate => {
      if (rentsByPlate.length === 0) {
        Swal.fire('Ehhez az autóhoz jelenleg nem tartozik bérlés!', '', 'info');
      }
      this.dataSource = new MatTableDataSource(rentsByPlate);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  updateRent(rent): void {
    Swal.fire({
      title: 'Biztos, hogy szerkeszteni akarod ezt a bérlést?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen',
      cancelButtonText: 'Nem'
    }).then((result) => {
      if (result.isConfirmed) {
        this.showTable = false;
        this.updateRentPage = true;
        this.rentToUpdate = rent;
        this.rentToUpdate.kmh_state_after = rent.kmh_state_before;
        this.rentToUpdate.rent_start = new Date(this.rentToUpdate.rent_start.replace(' ', 'T'));
        this.rentToUpdate.rent_end = new Date(this.rentToUpdate.rent_end.replace(' ', 'T'));
        this.rentToUpdate.takeaway_start = new Date(this.rentToUpdate.takeaway_start.replace(' ', 'T'));
        this.rentToUpdate.takeaway_end = new Date(this.rentToUpdate.takeaway_end.replace(' ', 'T'));
      }
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  showCarsPage(): void {
    this.carsList.rentByCarPage = false;
    this.freeCarsList.rentByCarPage = false;
  }

}
