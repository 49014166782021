import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Renter} from '../models/renter.model';
import {AppService} from '../app.service';
import Swal from 'sweetalert2';
import {RentersComponent} from '../renters/renters.component';
import {Observable} from 'rxjs';

export interface RentData {
  id: string;
  rent_start: string;
  rent_end: string;
  deposit: string;
  car_plate: string;
  kmh_state_before: string;
  kmh_state_after: string;
  takeaway_start: string;
  takeaway_end: string;
}

@Component({
  selector: 'app-add-renter',
  templateUrl: './add-renter.component.html',
  styleUrls: ['./add-renter.component.css']
})

export class AddRenterComponent implements OnInit {

  renterForm = new FormGroup({
    name: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl(''),
    motherName: new FormControl(''),
    address: new FormControl(''),
    idNumber: new FormControl(''),
    licenseType: new FormControl(''),
    licenseNumber: new FormControl(''),
    nationality: new FormControl(''),
    payment: new FormControl(''),
  });

  hasRents = false;
  getRents = new Observable();
  finishedRents = [];
  unfinishedRents = [];
  licenseTypes = ['EU', 'HUN', 'OTH'];
  paymentTypes = ['KP', 'BANK'];
  @Input() public renter = new Renter();
  @Input() update = false;
  @Input() newRenter = false;

  constructor(
    private service: AppService,
    private renterList: RentersComponent
  ) {
  }

  ngOnInit(): void {
    if (this.renter === undefined) {
      this.renter = new Renter();
    }

    if (this.update) {
      this.getRents = this.service.getListByRenterId(this.renter.id);
      this.getRents.subscribe(res => {
        // @ts-ignore
        this.finishedRents = res.data['active-rents'];
        // @ts-ignore
        this.unfinishedRents = res.data['finished-rents'];
      });
    }
  }

  onSubmit(): void {
    console.warn(this.renterForm.value);
  }

  createRenter(): void {
    if (this.renterForm.status === 'VALID') {
      this.renter.deleted = 0;
      this.service.createModel(this.renter, 'renter').subscribe(res => {
        Swal.fire('Bérlő sikeresen létrehozva!', '', 'success');
        this.renterForm.reset();
      }, error => {
        let emailError = '';
        let phoneError = '';
        let idNumberError = '';
        let lisenceError = '';

        if (error.error.data.errors.email !== undefined ){
          emailError = error.error.data.errors.email[0];
        }

        if (error.error.data.errors.phone !== undefined ){
          phoneError = error.error.data.errors.phone[0];
        }

        if (error.error.data.errors.id_number !== undefined ){
          idNumberError = error.error.data.errors.id_number[0];
        }

        if (error.error.data.errors.license_number !== undefined ){
          lisenceError = error.error.data.errors.license_number[0];
        }


        if (idNumberError || phoneError || lisenceError && lisenceError !== '' && idNumberError !== '') {
          Swal.fire(emailError + '\n' + phoneError + '\n' + idNumberError + '\n' + lisenceError + '\n', '', 'warning');
        } else {
          Swal.fire({
            title: emailError + '\n' + phoneError + '\n' + 'Biztos, hogy létre akarod hozni a bérlőt?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Igen',
            cancelButtonText: 'Nem',
          }).then((result) => {
            if (result.isConfirmed) {
              this.renter.force_create = 1;
              this.service.createModel(this.renter, 'renter').subscribe(res => {
                Swal.fire('Bérlő sikeresen létrehozva!', '', 'success');
                this.renterForm.reset();
              });
            }
          });
        }
      });
    } else {
      Swal.fire('Töltsd ki a kötelező mezőket!', '', 'warning');
    }
  }

  updateRenter(): void {
    this.renter.deleted = 0;
    this.service.updateModel(this.renter, 'renter').subscribe(res => {
      Swal.fire('Bérlő sikeresen módosítva', '', 'success');
      this.renterList.getRentersList();
      this.renterList.updateRenterPage = false;
    });
  }

  deleteRenter(): void {
    Swal.fire({
      title: 'Biztos, hogy törölni akarod ezt a bérlőt?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen',
      cancelButtonText: 'Nem'
    }).then((result) => {
      if (result.isConfirmed) {
        this.renter.deleted = 1;
        this.service.deleteModel(this.renter, 'renter').subscribe(res => {
          Swal.fire('Bérlő sikeresen törölve!', '', 'success');
          this.renterList.getRentersList();
          this.renterList.updateRenterPage = false;
        }, error => {
          Swal.fire(error.error.data.message, '', 'warning');
        });
      }
    });
  }

}
