import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AppService} from '../app.service';
import Swal from 'sweetalert2';
// @ts-ignore
import moment = require('moment/moment');

export interface RentData {
  id: string;
  rent_start: string;
  rent_end: string;
  deposit: string;
  car_plate: string;
  kmh_state_before: string;
  kmh_state_after: string;
  takeaway_start: string;
  takeaway_end: string;
  finished: string;
}

@Component({
  selector: 'app-rents-by-renter',
  templateUrl: './rents-by-renter.component.html',
  styleUrls: ['./rents-by-renter.component.css']
})

export class RentsByRenterComponent implements OnInit {

  rentToUpdate: any;
  updateRentPage = false;
  rents: any;
  listLength = 0;
  filterValue = '';
  list = [];

  @Input()
  set asyncList(val: any) {
    this.list = val;
    this.createMatList(this.list);
  }


  @Input() tableName = '';
  @Input() renter: any;

  displayedColumns: string[] = ['id', 'rent_start', 'rent_end', 'deposit', 'car_plate', 'kmh_state_before', 'kmh_state_after', 'takeaway_start', 'takeaway_end'];
  dataSource: MatTableDataSource<RentData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {
  }

  ngOnInit(): void {
  }

  applyFilter(): void {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  createMatList(list): void {
    this.listLength = list.length;
    this.dataSource = new MatTableDataSource(list);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  updateRent(rent): void {
    if (rent.finished === 1) {
      Swal.fire({
        title: 'Ez a bérlés már lezárt állapotú. Biztos, hogy szerkeszteni akarod?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Igen',
        cancelButtonText: 'Nem'
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateRentPage = true;
          this.rentToUpdate = rent;
          if (rent.kmh_state_before !== rent.kmh_state_after) {
            this.rentToUpdate.kmh_state_after = rent.kmh_state_before;
          }
          this.rentToUpdate.renter_id = this.renter.id;
          this.rentToUpdate.rent_start = moment(this.rentToUpdate.rent_start.replace(' ', 'T'));
          this.rentToUpdate.rent_end = moment(this.rentToUpdate.rent_end.replace(' ', 'T'));
          this.rentToUpdate.takeaway_start = moment(this.rentToUpdate.takeaway_start.replace(' ', 'T'));
          this.rentToUpdate.takeaway_end = moment(this.rentToUpdate.takeaway_end.replace(' ', 'T'));
        }
      });
    } else {
      this.updateRentPage = true;
      this.rentToUpdate = rent;
      if (rent.kmh_state_before !== rent.kmh_state_after) {
        this.rentToUpdate.kmh_state_after = rent.kmh_state_before;
      }
      this.rentToUpdate.renter_id = this.renter.id;
      this.rentToUpdate.rent_start = moment(this.rentToUpdate.rent_start.replace(' ', 'T'));
      this.rentToUpdate.rent_end = moment(this.rentToUpdate.rent_end.replace(' ', 'T'));
      this.rentToUpdate.takeaway_start = moment(this.rentToUpdate.takeaway_start.replace(' ', 'T'));
      this.rentToUpdate.takeaway_end = moment(this.rentToUpdate.takeaway_end.replace(' ', 'T'));
    }
  }

}
