import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from 'src/app/app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {MatInputModule} from '@angular/material/input';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatOptionModule} from '@angular/material/core';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {CustomNgxDatetimeAdapter} from './CustomNgxDatetimeAdapter';
import {GoogleChartsModule} from 'angular-google-charts';
import {NgApexchartsModule} from 'ng-apexcharts';

import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateAdapter,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';

import {AuthGuardService} from './auth-guard.service';
import {PersistanceService} from './persistance.service';

import {AppComponent} from './app.component';
import {CarsComponent} from './cars/cars.component';
import {HomeComponent} from './home/home.component';
import {RentsComponent} from './rents/rents.component';
import {RentersComponent} from './renters/renters.component';
import {LoginComponent} from './login/login.component';
import {AddCarComponent} from './add-car/add-car.component';
import {AddRenterComponent} from './add-renter/add-renter.component';
import {AddRentComponent} from './add-rent/add-rent.component';
import {CarsFreeComponent} from './cars-free/cars-free.component';
import {SummarizeComponent} from './summarize/summarize.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {WarningsComponent} from './warnings/warnings.component';
import {SettingsComponent} from './settings/settings.component';
import {LogComponent} from './log/log.component';
import {IntervalSearchComponent} from './interval-search/interval-search.component';
import {RentsByCarComponent} from './rents-by-car/rents-by-car.component';
import {RentsByRenterComponent} from './rents-by-renter/rents-by-renter.component';
import {RentsListComponent} from './rents-list/rents-list.component';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter} from '@angular/material/core';

// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {QuickRentComponent} from './quick-rent/quick-rent.component';
import {UpdateRentComponent} from './update-rent/update-rent.component';
import {FinishedRentsComponent} from './finished-rents/finished-rents.component';
import {UnfinishedRentsComponent} from './unfinished-rents/unfinished-rents.component';
import { TomorrowComponent } from './tomorrow/tomorrow.component';
import {SummarizeComponent2} from './summarize-2/summarize2.component';
import {TodayComponent} from './today/today.component';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS'
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@NgModule({
  declarations: [
    AppComponent,
    CarsComponent,
    HomeComponent,
    RentsComponent,
    RentersComponent,
    LoginComponent,
    AddCarComponent,
    AddRenterComponent,
    AddRentComponent,
    CarsFreeComponent,
    SummarizeComponent,
    SummarizeComponent2,
    WarningsComponent,
    SettingsComponent,
    LogComponent,
    IntervalSearchComponent,
    RentsByCarComponent,
    RentsByRenterComponent,
    RentsListComponent,
    QuickRentComponent,
    UpdateRentComponent,
    FinishedRentsComponent,
    UnfinishedRentsComponent,
    TomorrowComponent,
    TodayComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    FormsModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    NgxMatSelectSearchModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatCheckboxModule,
    SweetAlert2Module.forRoot(),
    GoogleChartsModule,
    NgApexchartsModule
  ],
  providers: [

    AuthGuardService,
    MatDatepickerModule,
    AddCarComponent,
    AddRenterComponent,
    PersistanceService,
    RentersComponent,
    RentsComponent,
    RentsListComponent,
    CarsComponent,
    CarsFreeComponent,
    SummarizeComponent,
    SummarizeComponent2,
    IntervalSearchComponent,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},

    {
      provide: NgxMatDateAdapter,
      useClass: CustomNgxDatetimeAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
