import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Rent} from '../models/rent.model';
import {AppService} from '../app.service';
import {AddRenterComponent} from '../add-renter/add-renter.component';
import Swal from 'sweetalert2';
import {RentsComponent} from '../rents/rents.component';
import {RentsListComponent} from '../rents-list/rents-list.component';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-rent',
  templateUrl: './add-rent.component.html',
  styleUrls: ['./add-rent.component.css']
})
export class AddRentComponent implements OnInit {

  rentForm = new FormGroup({
    rentStart: new FormControl('', Validators.required),
    rentEnd: new FormControl(''),
    renterId: new FormControl('', Validators.required),
    deposit: new FormControl('', Validators.required),
    filterId: new FormControl(''),
    carPlate: new FormControl('', Validators.required),
    filterCar: new FormControl(''),
    kmhStateBefore: new FormControl('', Validators.required),
    kmhStateAfter: new FormControl(''),
    takeawayStart: new FormControl(''),
    takeawayEnd: new FormControl(''),
    note: new FormControl(''),
  });

  @Input() rent = new Rent();
  @Input() carToRent = false;
  @Input() carPlate = '';
  @Input() kmhStateBefore = '';
  @Input() update = false;

  newRenter = false;
  renterCreated = false;
  renters = [];
  cars = [];
  filteredRenters = [];
  filteredCars = [];
  idNumberFilter = '';
  carFilter = '';
  takeawayWarning = '';

  tempRentStart: any;
  tempRentEnd: any;
  tempTakeawayStart: any;
  tempTakeawayEnd: any;

  getRents = new Observable();

  @ViewChild(AddRenterComponent) renterViewChild: AddRenterComponent;

  constructor(
    private service: AppService,
    private rentList: RentsComponent,
    private rentsList: RentsListComponent,
    private router: Router
  ) {
  }

  ngOnInit(): void {

    this.rentForm.controls.kmhStateBefore.disable();

    if (this.update) {
      this.rentForm.controls.deposit.disable();
      this.rent.renter_id = this.rent.renter_id.toString();
      console.log(this.rent);
    }

    this.service.getListByModel('renter').subscribe(renters => {
      this.filteredRenters = renters;
      this.renters = renters;
    });

    this.service.getRentableCars().subscribe(cars => {
      this.filteredCars = cars;
      this.cars = cars;
    });

    if (this.rent === undefined) {
      this.rent = new Rent();
    }

    if (this.carToRent) {
      this.rentForm.controls.carPlate.disable();
      this.rentForm.controls.renterId.clearValidators();
      this.rent.car_plate = this.carPlate;
      this.rent.kmh_state_before = this.kmhStateBefore;
      this.rent.kmh_state_after = this.kmhStateBefore;
    }
  }

  createRent(): void {
    const yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1);

    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);


    if (this.rent.rent_start > this.rent.rent_end) {
      Swal.fire('A foglalás végé korábban van a foglalás kezdeténél!', '', 'error');
      // @ts-ignore
    } else if (this.rent.takeaway_start > tomorrow) {
      Swal.fire('A telephelyre elvitte dátum nem lehet későbbi mint a mai nap!', '', 'error');
      // @ts-ignore
    } else if (this.rent.takeaway_end !== null && this.rent.takeaway_start > this.rent.takeaway_end) {
      Swal.fire('A telephelyről visszahozta dátum nem lehet korábban a telephelyre elvitte dátumnál!', '', 'error');
    } else if (this.rent.takeaway_end !== null && this.rent.rent_start > this.rent.takeaway_end) {
      Swal.fire('A telephelyre visszahozta dátum nem lehet korábban a foglalás kezdeténél!', '', 'error');
    } else if (!this.rentForm.valid) {
      Swal.fire('Add meg a hiányzó adatokat!', '', 'error');
    } else {

      if (this.rentForm.status === 'VALID') {
        this.saveTemporaryDates();
        this.rent.rent_start = this.momentDateConverter(this.rent.rent_start);
        this.rent.rent_end = this.momentDateConverter(this.rent.rent_end);
        this.rent.takeaway_start = this.momentDateConverter(this.rent.takeaway_start);
        this.rent.takeaway_end = this.momentDateConverter(this.rent.takeaway_end);
        this.rent.finished = 0;
        this.rent.deleted = 0;
        this.service.createModel(this.rent, 'rent').subscribe(res => {
          if (res === 1) {
            Swal.fire('Bérlés sikeresen elindítva!', '', 'success');
            this.rentForm.reset();
          } else {
            this.loadTemporaryDates();
            let msg = '';
            res.forEach(rent => {
              msg = msg.concat(rent.rent_start + '\n - \n ' + rent.rent_end + '\n\n' + '<a href="/berles-szerkesztes/' + rent.id + '">Bérléshez</a>' + '\n\n');
            });
            Swal.fire('A bérlés ütközik az alábbi foglalásokkal', msg, 'warning');
          }
        });
      } else {
        Swal.fire('Töltsd ki a kötelező mezőket!', '', 'warning');
      }
    }

  }

  updateRent(): void {
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);

    // @ts-ignore
    if (this.rent.rent_start > this.rent.rent_end) {
      Swal.fire('A foglalás végé korábban van a foglalás kezdeténél!', '', 'error');
    } else if (this.rent.takeaway_end !== null && this.rent.takeaway_start > this.rent.takeaway_end) {
      Swal.fire('A telephelyről visszahozta dátum nem lehet korábban a telephelyre elvitte dátumnál!', '', 'error');
    } else if (this.rent.kmh_state_after !== null && this.rent.kmh_state_after < this.rent.kmh_state_before) {
      Swal.fire('A bérlés utáni km óra állás nem lehet kevesebb a bérlés előtti km állásnál!', '', 'error');
      // @ts-ignore
    } else if (this.rent.takeaway_start > tomorrow) {
      Swal.fire('A telephelyre elvitte dátum nem lehet későbbi mint a mai nap!', '', 'error');
      // @ts-ignore
    } else if (this.rent.takeaway_end !== null && this.rent.rent_start > this.rent.takeaway_end) {
      Swal.fire('A telephelyre visszahozta dátum nem lehet korábban a foglalás kezdeténél!', '', 'error');
    } else if (!this.rentForm.valid) {
      Swal.fire('Add meg a hiányzó adatokat!', '', 'error');
    } else {
      this.saveTemporaryDates();
      this.updateServiceCall('update');
    }
  }

  finishRent(): void {
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);

    this.checkTakeaway(this.rent.takeaway_end);

    if (this.rent.rent_start > this.rent.rent_end) {
      Swal.fire('A foglalás végé korábban van a foglalás kezdeténél!', '', 'error');
    } else if (this.rent.takeaway_end !== null && this.rent.takeaway_start > this.rent.takeaway_end) {
      Swal.fire('A telephelyről visszahozta dátum nem lehet korábban a telephelyre elvitte dátumnál!', '', 'error');
    } else if (this.rent.kmh_state_after !== null && this.rent.kmh_state_after < this.rent.kmh_state_before) {
      Swal.fire('A bérlés utáni km óra állás nem lehet kevesebb a bérlés előtti km állásnál!', '', 'error');
    } else if (!this.rentForm.valid) {
      Swal.fire('Add meg a hiányzó adatokat!', '', 'error');
    } else if (this.rent.takeaway_end === null) {
      Swal.fire('Nincs megadva a Visszahozta a telephelyre dátum!', '', 'error');
      // @ts-ignore
    } else if (this.rent.takeaway_end !== null && this.rent.rent_start > this.rent.takeaway_end) {
      Swal.fire('A telephelyre visszahozta dátum nem lehet korábban a foglalás kezdeténél!', '', 'error');
    } else {
      if (this.takeawayWarning || this.takeawayWarning === '') {
        Swal.fire({
          title: this.takeawayWarning + '\n \n Biztosan lezárod a bérlést?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Igen',
          cancelButtonText: 'Nem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.rent.rent_start = this.momentDateConverter(this.rent.rent_start);
            this.rent.rent_end = this.momentDateConverter(this.rent.rent_end);
            this.rent.takeaway_start = this.momentDateConverter(this.rent.takeaway_start);
            this.rent.takeaway_end = this.momentDateConverter(this.rent.takeaway_end);
            this.rent.finished = 1;
            this.updateServiceCall('finish');
          }
        });
      }
    }
  }

  deleteRent(): void {
    Swal.fire({
      title: 'Biztosan törölni akarod a bérlést?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen',
      cancelButtonText: 'Nem'
    }).then((result) => {
      if (result.isConfirmed) {
        this.rent.deleted = 1;
        this.service.deleteModel(this.rent, 'rent').subscribe(res => {
          Swal.fire('A bérlést sikeresen törölted!', '', 'success');
        });
        this.router.navigate(['/berlesek']);
      }
    });
  }

  onSubmit(): void {
    console.warn(this.rentForm.value);
  }

  filterIds(filterId): void {
    this.filteredRenters = [];
    this.renters.forEach(renter => {
      const renterNameWithId = renter.name + renter.id_number;
      if (renterNameWithId && renterNameWithId.toLowerCase().includes(filterId.toLowerCase())) {
        this.filteredRenters.push(renter);
      }
    });
  }

  filterCars(filterCar): void {
    this.filteredCars = [];
    this.cars.forEach(car => {
      const carPlateWithBrand = car.plate + car.brand;
      if (carPlateWithBrand && carPlateWithBrand.toLowerCase().includes(filterCar.toLowerCase())) {
        this.filteredCars.push(car);
      }
    });
  }

  setKmhBeforeState(): void {
    const selectedCar = this.cars.filter(car => car.plate === this.rent.car_plate);
    if (selectedCar.length !== 0) {
      this.rent.kmh_state_before = selectedCar[0].kmh_state;
    }
  }

  momentDateConverter(date): string {
    if (!date || typeof date === 'string') {
      return;
    }

    if (date.year === undefined) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      let hours = date.getHours();
      let minutes = date.getMinutes();

      if (hours < 10) {
        hours = '0' + hours;
      }

      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00';
    } else {
      const year = date.year();
      const month = date.month() + 1;
      const day = date.date();

      let hours = date.hour();
      let minutes = date.minute();

      if (hours < 10) {
        hours = '0' + hours;
      }

      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00';
    }
  }

  checkTakeaway(takeawayEnd): void {
    let result: any;
    if (takeawayEnd) {
      const day = 1000 * 60 * 60 * 24;
      const rentEnd = new Date(this.rent.rent_end);

      if (takeawayEnd && takeawayEnd.getDate) {
        result = Math.round(rentEnd.getTime() - takeawayEnd.getTime()) / (day);
      } else if (takeawayEnd && takeawayEnd.getDate === undefined) {
        result = Math.round(rentEnd.getTime() - takeawayEnd.unix() * 1000) / (day);
      }

      let daysDiff = result.toFixed(0);
      if (Number(daysDiff) > 0) {
        this.takeawayWarning = 'A bérlés végéhez képest ' + daysDiff + ' nappal hamarabb hozták vissza az autót';
      } else if (Number(daysDiff) < 0) {
        daysDiff = daysDiff.substring(1);
        this.takeawayWarning = 'A bérlés végéhez képest ' + daysDiff + ' nappal később hozták vissza az autót';
      } else {
        return;
      }
    } else {
      return;
    }
  }

  updateServiceCall(mode): void {
    if (mode === 'finish') {
      this.service.updateModel(this.rent, 'rent').subscribe(res => {
        // @ts-ignore
        if (res && res.data) {
          this.loadTemporaryDates();
          const alertMsg = 'Bérlés sikeresen lezárva';
          if (res.data.deposit > 0) {
            Swal.fire(alertMsg + '\nTovábbi bentlévő kaució: ' + res.data.deposit + ' Ft', '', 'success');
            this.router.navigate(['/berlesek']);
          } else {
            Swal.fire(alertMsg, '', 'success');
            this.router.navigate(['/berlesek']);
          }
        }
      });
    } else if (mode === 'update') {
      this.saveTemporaryDates();
      this.rent.rent_start = this.momentDateConverter(this.rent.rent_start);
      this.rent.rent_end = this.momentDateConverter(this.rent.rent_end);
      this.rent.takeaway_start = this.momentDateConverter(this.rent.takeaway_start);
      this.rent.takeaway_end = this.momentDateConverter(this.rent.takeaway_end);
      this.rent.finished = 0;
      this.rent.deleted = 0;
      this.service.updateModel(this.rent, 'rent').subscribe(res => {
        // @ts-ignore
        if (res && res.data) {
          this.loadTemporaryDates();
          this.rent.rent_start = this.momentDateConverter(this.rent.rent_start);
          this.rent.rent_end = this.momentDateConverter(this.rent.rent_end);
          this.rent.takeaway_start = this.momentDateConverter(this.rent.takeaway_start);
          this.rent.takeaway_end = this.momentDateConverter(this.rent.takeaway_end);
          this.service.updateModel(this.rent, 'rent').subscribe(createRes => {
            const alertMsg = 'Bérlés sikeresen módosítva';
            if (createRes.data.deposit > 0) {
              Swal.fire(alertMsg + '\nTovábbi bentlévő kaució: ' + createRes.data.deposit + ' Ft', '', 'success');
              // this.router.navigate(['/berlesek']);
            } else {
              Swal.fire(alertMsg, '', 'success');
              // this.router.navigate(['/berlesek']);
            }
          });
        } else {
          this.loadTemporaryDates();
          let msg = '';
          res.forEach(rent => {
            msg = msg.concat(rent.rent_start + '\n - \n ' + rent.rent_end + '\n\n' + '<a href="/berles-szerkesztes/' + rent.id + '">Bérléshez</a>' + '\n\n');
          });
          Swal.fire('A bérlés ütközik az alábbi foglalásokkal', msg, 'warning');
        }
      });
    }
  }

  createNewRenter(): void {
    const localRenter = this.renterViewChild.renter;
    // @ts-ignore
    localRenter.deleted = 0;
    this.service.createModel(localRenter, 'renter').subscribe(renterRes => {
      Swal.fire('Az új bérlő létrehozva!', 'A bérlés létrehozásához kérjük kattintson a Bérlés indítása gombra!', 'info');
      this.rent.renter_id = renterRes.data.id;
      this.rentForm.patchValue({
        renterId: renterRes.data.id,
      });
      this.newRenter = false;
      this.renterCreated = true;
    }, error => {
      let emailError = '';
      let nameError = '';
      let phoneError = '';
      let idNumberError = '';
      let lisenceError = '';
      console.log(error);
      if (error.error.data.errors.name !== undefined) {
        nameError = error.error.data.errors.name[0];
      }

      if (error.error.data.errors.email !== undefined) {
        emailError = error.error.data.errors.email[0];
      }

      if (error.error.data.errors.phone !== undefined) {
        phoneError = error.error.data.errors.phone[0];
      }

      if (error.error.data.errors.id_number !== undefined) {
        idNumberError = error.error.data.errors.id_number[0];
      }

      if (error.error.data.errors.license_number !== undefined) {
        lisenceError = error.error.data.errors.license_number[0];
      }

      if (nameError || phoneError || idNumberError || lisenceError && lisenceError !== '' && idNumberError !== '') {
        Swal.fire('\n' + nameError + '\n' + emailError + '\n' + phoneError + '\n' + idNumberError + '\n' + lisenceError + '\n', '', 'warning');
      }
    });
  }

  saveTemporaryDates(): void {
    this.tempRentStart = this.rent.rent_start;
    this.tempRentEnd = this.rent.rent_end;
    this.tempTakeawayStart = this.rent.takeaway_start;
    this.tempTakeawayEnd = this.rent.takeaway_end;
  }

  loadTemporaryDates(): void {
    this.rent.rent_start = this.tempRentStart;
    this.rent.rent_end = this.tempRentEnd;
    this.rent.takeaway_start = this.tempTakeawayStart;
    this.rent.takeaway_end = this.tempTakeawayEnd;
  }

  setTimeNow(takeaway): void {
    // @ts-ignore
    this.rent[takeaway] = new Date();
  }
}
