import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Car} from '../models/car.model';
import {AppService} from '../app.service';
import Swal from 'sweetalert2';
import {CarsComponent} from '../cars/cars.component';
import {CarsFreeComponent} from '../cars-free/cars-free.component';
import {IntervalSearchComponent} from '../interval-search/interval-search.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-car',
  templateUrl: './add-car.component.html',
  styleUrls: ['./add-car.component.css']
})

export class AddCarComponent implements OnInit {

  carForm = new FormGroup({
    plate: new FormControl('', Validators.required),
    sizeCategory: new FormControl('', Validators.required),
    active: new FormControl('', Validators.required),
    fuel: new FormControl('', Validators.required),
    brand: new FormControl('', Validators.required),
    image: new FormControl(''),
    serviceInterval: new FormControl('', Validators.required),
    vignetteExpiration: new FormControl(''),
    serviceDate: new FormControl(''),
    carDocumentExpiration: new FormControl(''),
    kmhState: new FormControl('', Validators.required),
    note: new FormControl(''),
  });

  fileForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  filedata: any;
  sizeCategories = ['kis', 'közép', 'egyterű', 'mikrobusz', 'teherautó'];
  fuelCategories = ['benzin', 'dízel', 'hibrid', 'elektromos'];
  @Input() car = new Car();
  @Input() update = false;

  constructor(
    private service: AppService,
    private carsList: CarsComponent,
    private freeCarsList: CarsFreeComponent,
    private intervalCarsList: IntervalSearchComponent,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    if (this.car === undefined) {
      this.car = new Car();
      this.car.active = true;
    }
    this.car.active = true;

    if (this.update === true) {
      this.carForm.controls.plate.disable();
    }
  }

  createCar(): void {
    const formData = new FormData();
    formData.append('image', this.fileForm.get('fileSource').value);

    if (this.car && this.car.active === undefined || this.car.active === false) {
      this.car.active = false;
    }

    if (this.car.service_date) {
      this.car.service_date = this.datetimeConverter(this.car.service_date);
    }

    if (this.car.vignette_expiration) {
      this.car.vignette_expiration = this.dateConverter(this.car.vignette_expiration);

    }

    if (this.car.car_document_expiration) {
      this.car.car_document_expiration = this.dateConverter(this.car.car_document_expiration);
    }

    this.car.deleted = 0;

    if (this.fileForm.get('fileSource').value.name) {
      this.car.image = this.fileForm.get('fileSource').value.name;
    }

    if (this.carForm.status === 'VALID') {
      this.service.createModel(this.car, 'car').subscribe(res => {
        this.service.uploadImageFile(formData).subscribe(imgRes => {
          Swal.fire('Auto sikeresen létrehozva', '', 'success');
          this.carForm.reset();
        });
      });
    } else {
      Swal.fire('Töltsd ki a kötelező mezőket!', '', 'warning');
    }
  }

  onSubmit(): void {
    console.warn(this.carForm.value);
  }

  showActive(active: boolean): string {
    if (active) {
      return 'Aktív';
    } else {
      return 'Nem Aktív';
    }
  }

  updateCar(): void {
    const formData = new FormData();
    formData.append('image', this.fileForm.get('fileSource').value);

    if (this.car.service_date) {
      this.car.service_date = this.datetimeObjConverter(new Date(this.car.service_date));
    }

    if (this.car.vignette_expiration) {
      this.car.vignette_expiration = this.dateConverter(this.car.vignette_expiration);
    }

    if (this.car.car_document_expiration) {
      this.car.car_document_expiration = this.dateConverter(this.car.car_document_expiration);
    }

    this.car.deleted = 0;

    if (this.fileForm.get('fileSource').value.name) {
      this.car.image = this.fileForm.get('fileSource').value.name;
    }
    if (this.carForm.status === 'VALID') {
      console.log(this.car);
      this.service.updateModel(this.car, 'car').subscribe(() => {
        this.service.uploadImageFile(formData).subscribe(() => {
          Swal.fire('Auto sikeresen módosítva!', '', 'success');
          if (this.carsList.updateCarPage) {
            this.carsList.updateCarPage = false;
            this.carsList.getCarList();
          } else if (this.freeCarsList.updateCarPage) {
            this.freeCarsList.updateCarPage = false;
            this.freeCarsList.getCarList();
          } else if (this.intervalCarsList.updateCarPage) {
            this.intervalCarsList.updateCarPage = false;
            this.intervalCarsList.getCarList();
          }
          void this.router.navigate(['/autok']);

        });
      });
    } else {
      Swal.fire('Töltsd ki a kötelező mezőket!', '', 'warning');
    }
  }

  onFileChange(event): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileForm.patchValue({
        fileSource: file
      });
    }
  }

  datetimeConverter(date): string {
    if (!date) {
      return;
    }

    const year = date.year();
    const month = date.month() + 1;
    const day = date.date();

    let hours = date.hour();
    let minutes = date.minute();

    if (hours < 10) {
      hours = '0' + hours;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00';
  }

  datetimeObjConverter(date): string {
    if (!date) {
      return;
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    if (hours < 10) {
      hours = '0' + hours;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00';
  }


  dateConverter(date): string {
    date = new Date(date);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return year + '-' + month + '-' + day;
  }
}
