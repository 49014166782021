<div class="new-model-component form-component">
  <h1 *ngIf="!update">Új bérlő hozzáadása</h1>
  <h1 *ngIf="update">Bérlő szerkesztése</h1>

  <form [formGroup]="renterForm" (ngSubmit)="onSubmit()">

    <mat-form-field class="" appearance="fill">
      <mat-label>Név</mat-label>
      <input [(ngModel)]="renter.name" matInput formControlName="name" pattern="^[-a-zA-Z_&`´#. áéíóúőŐÁÉÍÓÚÑñÇç]*$">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Telefonszám</mat-label>
      <input [(ngModel)]="renter.phone" matInput type="tel" formControlName="phone" pattern="^[0-9*/+-]*$">
    </mat-form-field>

    <div class="spacer"></div>

    <!--    ToDo: email validator-->

    <mat-form-field class="" appearance="fill">
      <mat-label>E-mail</mat-label>
      <input [(ngModel)]="renter.email" matInput type="email" formControlName="email"
             pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Anyja neve</mat-label>
      <input [(ngModel)]="renter.mother_name" matInput formControlName="motherName"
             pattern="^[-a-zA-Z_&`´#. áéíóúőŐÁÉÍÓÚÑñÇç]*$">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Személyi azonosító</mat-label>
      <input [(ngModel)]="renter.id_number" matInput formControlName="idNumber">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Cím</mat-label>
      <input [(ngModel)]="renter.address" matInput formControlName="address">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Jogosítvány típusa</mat-label>
      <mat-select [(ngModel)]="renter.license_type" formControlName="licenseType">
        <mat-option *ngFor="let licenseType of licenseTypes" value="{{licenseType}}">{{licenseType}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Jogosítvány</mat-label>
      <input [(ngModel)]="renter.license_number" matInput formControlName="licenseNumber">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Nemzetiség</mat-label>
      <input [(ngModel)]="renter.nationality" matInput formControlName="nationality"
             pattern="^[-a-zA-Z_&`´#. áéíóúőŐÁÉÍÓÚÑñÇç]*$">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Fizetés típusa</mat-label>
      <mat-select [(ngModel)]="renter.payment" formControlName="payment">
        <mat-option *ngFor="let paymentType of paymentTypes" value="{{paymentType}}">{{paymentType}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="spacer"></div>

    <button *ngIf="!update && !newRenter" mat-raised-button (click)="createRenter()">Mentés</button>
    <button *ngIf="update" mat-raised-button (click)="updateRenter()">Mentés</button>
    <button *ngIf="update" mat-raised-button (click)="deleteRenter()">Törlés</button>
  </form>

  <div class="spacer"></div>

</div>

<div *ngIf="getRents | async">
  <app-rents-by-renter [renter]="renter" [asyncList]="finishedRents" [tableName]="'Aktív rendelések'"></app-rents-by-renter>
  <app-rents-by-renter [renter]="renter" [asyncList]="unfinishedRents" [tableName]="'Lezárt rendelések'"></app-rents-by-renter>
</div>
