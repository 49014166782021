<div class="new-model-component form-component">
  <h1 *ngIf="!update">Új bérlés hozzáadása</h1>
  <h1 *ngIf="update">Bérlés szerkesztése</h1>

  <form [formGroup]="rentForm" (ngSubmit)="onSubmit()">

    <mat-form-field class="" appearance="fill">
      <mat-label>Foglalás kezdete</mat-label>
      <input [(ngModel)]="rent.rent_start" matInput [ngxMatDatetimePicker]="rentStart" formControlName="rentStart">
      <mat-datepicker-toggle matSuffix [for]="rentStart"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #rentStart [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                               [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'"
                               [enableMeridian]="false" [disableMinute]="true" [defaultTime]="[12, 0]" [hideTime]="false">
        <ng-template>
          <span>OK</span>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Foglalás vége</mat-label>
      <input [(ngModel)]="rent.rent_end" matInput [ngxMatDatetimePicker]="rentEnd" formControlName="rentEnd">
      <mat-datepicker-toggle matSuffix [for]="rentEnd"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #rentEnd [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                               [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'"
                               [enableMeridian]="false" [disableMinute]="true" [defaultTime]="[12, 0]" [hideTime]="false">
        <ng-template>
          <span>OK</span>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field *ngIf="!newRenter && !renterCreated" appearance="fill">
      <mat-label>Bérlő személyi azonosítója</mat-label>
      <mat-select [(ngModel)]="rent.renter_id" formControlName="renterId">
        <mat-option>
          <ngx-mat-select-search [(ngModel)]="idNumberFilter" (ngModelChange)="filterIds(idNumberFilter)" type="text"
                                 noEntriesFoundLabel="nincs találat"
                                 formControlName="filterId" placeholderLabel="keresés"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let renter of filteredRenters" value="{{renter.id}}">
          {{renter.name + ' (' + renter.id_number + ')'}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button *ngIf="!newRenter && !renterCreated && !rent.renter_id" class="add-renter-button" mat-raised-button (click)="newRenter = true">
      +
    </button>
    <button *ngIf="newRenter && !renterCreated" class="add-renter-button takeout-renter-button" mat-raised-button
            (click)="newRenter = false">-
    </button>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Kaució</mat-label>
      <input [(ngModel)]="rent.deposit" matInput formControlName="deposit">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field appearance="fill">
      <mat-label>Autó</mat-label>
      <mat-select [(ngModel)]="rent.car_plate" (ngModelChange)="setKmhBeforeState()" formControlName="carPlate">
        <mat-option [value]="rent.car_plate" disabled="">{{rent.car_plate}}</mat-option>
        <mat-option>
          <ngx-mat-select-search [(ngModel)]="carFilter" (ngModelChange)="filterCars(carFilter)" type="text"
                                 noEntriesFoundLabel="nincs találat"
                                 formControlName="filterCar" placeholderLabel="keresés"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let car of filteredCars" value="{{car.plate}}">
          {{car.brand + ' (' + car.plate + ')'}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Bérlés előtti km óra állás</mat-label>
      <input [(ngModel)]="rent.kmh_state_before" matInput formControlName="kmhStateBefore">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Bérlés utáni km óra állás</mat-label>
      <input [(ngModel)]="rent.kmh_state_after" matInput formControlName="kmhStateAfter">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Telephelyről elvitte</mat-label>
      <input [(ngModel)]="rent.takeaway_start" matInput [ngxMatDatetimePicker]="takeawayStart"
             formControlName="takeawayStart">
      <mat-datepicker-toggle matSuffix [for]="takeawayStart"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #takeawayStart [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                               [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'"
                               [enableMeridian]="false" [disableMinute]="true" [defaultTime]="[12, 0]" [hideTime]="false">
        <ng-template>
          <span>OK</span>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <button mat-raised-button (click)="setTimeNow('takeaway_start')">Elvitte most</button>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Telephelyre visszahozta</mat-label>
      <input [(ngModel)]="rent.takeaway_end" matInput [ngxMatDatetimePicker]="takeawayEnd"
             formControlName="takeawayEnd">
      <mat-datepicker-toggle matSuffix [for]="takeawayEnd"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #takeawayEnd [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                               [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'"
                               [enableMeridian]="false" [disableMinute]="true" [defaultTime]="[12, 0]" [hideTime]="false">
        <ng-template>
          <span>OK</span>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <button mat-raised-button (click)="setTimeNow('takeaway_end')">Visszahozta most</button>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Megjegyzés</mat-label>
      <input [(ngModel)]="rent.note" matInput formControlName="note">
    </mat-form-field>

    <div class="spacer"></div>

    <app-add-renter [newRenter]="newRenter" *ngIf="newRenter" #renterComponent></app-add-renter>

    <button *ngIf="newRenter" mat-raised-button (click)="createNewRenter()">Bérlő létrehozása az új bérléshez</button>
    <button *ngIf="!update && !newRenter" mat-raised-button (click)="createRent()">Bérlés indítása</button>
    <button *ngIf="update" mat-raised-button (click)="finishRent()">Bérlés befejezése</button>
    <button *ngIf="update" mat-raised-button (click)="updateRent()">Szerkesztés mentése</button>
    <button *ngIf="update" mat-raised-button (click)="deleteRent()">Törlés</button>
  </form>


</div>

