<div *ngIf="!rentCarPage && !rentByCarPage" class="new-model-component">
  <h1>Autók ({{carCounter}} db)</h1>

  <button *ngIf="!s" class="interval-button" mat-raised-button color="basic"
          (click)="s = true; m = false; l = false; xl = false; xxl = false; filterCarsBySize('kis')">Kis
  </button>
  <button *ngIf="s" class="interval-button" mat-raised-button color="primary">Kis
  </button>

  <button *ngIf="!m" class="interval-button" mat-raised-button color="basic"
          (click)="s = false; m = true; l = false; xl = false; xxl = false; filterCarsBySize('közép')">Közép
  </button>
  <button *ngIf="m" class="interval-button" mat-raised-button color="primary">Közép
  </button>

  <button *ngIf="!l" class="interval-button" mat-raised-button color="basic"
          (click)="s = false; m = false; l = true; xl = false; xxl = false; filterCarsBySize('egyterű')">Egyterű
  </button>
  <button *ngIf="l" class="interval-button" mat-raised-button color="primary">Egyterű</button>

  <button *ngIf="!xl" class="interval-button" mat-raised-button color="basic"
          (click)="s = false; m = false; l = false; xl = true; xxl = false; filterCarsBySize('mikrobusz')">Mikrobusz
  </button>
  <button *ngIf="xl" class="interval-button" mat-raised-button color="primary">Mikrobusz</button>

  <button *ngIf="!xxl" class="interval-button" mat-raised-button color="basic"
          (click)="s = false; m = false; l = false; xl = false; xxl = true; filterCarsBySize('teherautó')">Teherautó
  </button>
  <button *ngIf="xxl" class="interval-button" mat-raised-button color="primary">Teherautó</button>

  <div class="spacer"></div>

  <mat-icon *ngIf="updateCarPage" class="update-car" routerLink="/autok" (click)="undo()">undo</mat-icon>

  <mat-form-field *ngIf="!updateCarPage" appearance="standard">
    <mat-label>Keresés</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
  </mat-form-field>

  <div *ngIf="!updateCarPage" class="container">
    <div class="row">
      <div class="car-wrapper col-12 col-md-6" *ngFor="let car of filteredCars">
        <div class="car">

          <p class="more-weight">{{ car.brand + ' (' + car.plate + ')'}}</p>

          <img class="car-image" src="{{imgPath + car.image}}">

          <div class="same-line">
            <p class="more-weight">Méret: </p>
            <p>{{ car.category_size}} </p>

          </div>
          <p class="more-weight" *ngIf="car.active === 1"> aktív</p>
          <p class="more-weight" *ngIf="car.active === 0"> nem aktív</p>

          <div class="same-line">
            <p class="more-weight"> Üzemanyag: </p>
            <p> {{car.fuel}} </p>
          </div>

          <div class="same-line">
            <p class="more-weight"> Szervíz intervallum: </p>
            <p> {{car.service_interval}} km </p>
          </div>

          <p class="more-weight"> Autópálya matrica lejárata: </p>
          <p> {{checkVignette(car.vignette_expiration)}} </p>

          <p class="more-weight"> Forgalmi lejárata: </p>
          <p> {{checkDate(car.car_document_expiration)}} </p>

          <p class="more-weight"> Szervíz dőpont: </p>
          <p> {{checkServiceDate(car.service_date)}} </p>

          <div class="same-line">
            <p class="more-weight"> Km óra állás: </p>
            <p> {{car.kmh_state}} km </p>
          </div>

          <div class="same-line">
            <p class="more-weight"> Megjegyzés: </p>
            <p> {{car.note}} </p>
          </div>

          <mat-icon class="action-icon" (click)="deleteCar(car)">delete</mat-icon>
          <mat-icon class="action-icon" (click)="updateCar(car)">settings</mat-icon>
          <mat-icon class="action-icon" (click)="rentCar(car)">calendar_today</mat-icon>
          <mat-icon class="action-icon" (click)="showRents(car.plate)">event_available</mat-icon>
        </div>
      </div>
    </div>

  </div>

  <app-add-car [car]="carToUpdate" [update]="true" *ngIf="updateCarPage"></app-add-car>

</div>

<app-add-rent [carPlate]="carPlate" [kmhStateBefore]="kmhState" [carToRent]="true" *ngIf="rentCarPage"></app-add-rent>
<app-rents-by-car [plate]="carPlate" *ngIf="rentByCarPage"></app-rents-by-car>
