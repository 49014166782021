<div class="new-model-component form-component">
  <h1 *ngIf="!update">Új autó hozzáadása</h1>
  <h1 *ngIf="update">Autó szerkesztése</h1>

  <form [formGroup]="carForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data">

    <mat-form-field class="" appearance="fill">
      <mat-label>Méret szerinti kategória</mat-label>
      <mat-select [(ngModel)]="car.category_size" formControlName="sizeCategory" multiple>
        <mat-option *ngFor="let sizeCategory of sizeCategories" value="{{sizeCategory}}">{{sizeCategory}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-slide-toggle [(ngModel)]="car.active" [checked]="car.active"
                      formControlName="active"> {{showActive(car.active)}} </mat-slide-toggle>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Rendszám</mat-label>
      <input [(ngModel)]="car.plate" matInput formControlName="plate">
    </mat-form-field>

    <div class="spacer"></div>

    <label>Kép feltöltés</label>
    <br>
    <input [(ngModel)]="car.image" formControlName="image" type="file" (change)="onFileChange($event)"
           accept=".jpg, .jpeg, .png">

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Üzemanyag típusa</mat-label>
      <mat-select [(ngModel)]="car.fuel" formControlName="fuel">
        <mat-option *ngFor="let fuel of fuelCategories" value="{{fuel}}">{{fuel}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Márka/Típus</mat-label>
      <input [(ngModel)]="car.brand" matInput formControlName="brand">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Szervíz intervallum</mat-label>
      <input [(ngModel)]="car.service_interval" matInput formControlName="serviceInterval">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Autópálya matrica lejárata</mat-label>
      <input [(ngModel)]="car.vignette_expiration" matInput [matDatepicker]="vignetteExpiration"
             formControlName="vignetteExpiration">
      <mat-datepicker-toggle matSuffix [for]="vignetteExpiration"></mat-datepicker-toggle>
      <mat-datepicker #vignetteExpiration></mat-datepicker>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Szervíz időpont</mat-label>
      <input [(ngModel)]="car.service_date" matInput [ngxMatDatetimePicker]="serviceDate" formControlName="serviceDate">
      <mat-datepicker-toggle matSuffix [for]="serviceDate"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #serviceDate [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                               [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'"
                               [enableMeridian]="false" [disableMinute]="true" [defaultTime]="[12, 0]"
                               [hideTime]="false">
        <ng-template>
          <span>OK</span>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Forgalmi lejárata</mat-label>
      <input [(ngModel)]="car.car_document_expiration" matInput [matDatepicker]="carDocumentExpiration"
             formControlName="carDocumentExpiration">
      <mat-datepicker-toggle matSuffix [for]="carDocumentExpiration"></mat-datepicker-toggle>
      <mat-datepicker #carDocumentExpiration></mat-datepicker>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Km óra állás</mat-label>
      <input [(ngModel)]="car.kmh_state" matInput formControlName="kmhState">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Megjegyzés</mat-label>
      <input [(ngModel)]="car.note" matInput formControlName="note">
    </mat-form-field>

    <div class="spacer"></div>


    <button *ngIf="!update" mat-raised-button (click)="createCar()">Mentés</button>
    <button *ngIf="update" mat-raised-button (click)="updateCar()">Mentés</button>
  </form>
</div>
