import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PersistanceService} from '../persistance.service';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  userForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  username: '';
  password: '';

  constructor(
    public service: AppService,
    public persistanceService: PersistanceService,
    public router: Router
  ) {
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    console.warn(this.userForm.value);
  }

  login(): void {
    const data = {
      username: '',
      password: ''
    };

    data.username = this.username;
    data.password = this.password;

    this.service.login(data).subscribe(res => {
      if (res) {
        this.persistanceService.set('token', res.data.access_token);
        this.router.navigate(['/']);
      }
    }, error => {
      Swal.fire(error.error.data.message, '', 'warning');
    });
  }
}
