<div class="new-model-component section-1">
  <h1>Foglalások</h1>
  <div class="spacer"></div>

  <button *ngIf="dayInterval" class="interval-button" mat-raised-button color="primary">Napi</button>
  <button *ngIf="weekInterval || monthInterval"
          (click)="dayInterval = true; weekInterval = false; monthInterval = false; getDataByDay(today);"
          class="interval-button" mat-raised-button color="basic">Napi
  </button>

  <button *ngIf="weekInterval" class="interval-button" mat-raised-button color="primary">Heti</button>
  <button *ngIf="dayInterval || monthInterval"
          (click)="dayInterval = false; weekInterval = true; monthInterval = false; getDataByWeek(this.carDateStart, this.carDateEnd);"
          class="interval-button" mat-raised-button color="basic">Heti
  </button>

  <button *ngIf="monthInterval" class="interval-button" mat-raised-button color="primary">Havi</button>
  <button *ngIf="dayInterval || weekInterval"
          (click)="dayInterval = false; weekInterval = false; monthInterval = true"
          class="interval-button" mat-raised-button color="basic">Havi
  </button>

  <div class="spacer"></div>

  <div *ngIf="dayInterval">
    <mat-form-field class="week-picker" appearance="fill">
      <mat-label>Választott nap</mat-label>
      <input [(ngModel)]="today" (ngModelChange)="getDataByDay(today);" matInput [matDatepicker]="todaypick">
      <mat-datepicker-toggle matSuffix [for]="todaypick"></mat-datepicker-toggle>
      <mat-datepicker #todaypick></mat-datepicker>
    </mat-form-field>
  </div>

  <div *ngIf="weekInterval">
    <button class="arrow-button" (click)="changeIterators('dec')" mat-raised-button color="primary"><i
      class="material-icons">chevron_left</i></button>

    <mat-form-field class="week-picker" appearance="fill">
      <mat-label>Hétfő</mat-label>
      <input [(ngModel)]="monday" matInput disabled [matDatepicker]="mondaypick">
      <mat-datepicker-toggle matSuffix [for]="mondaypick"></mat-datepicker-toggle>
      <mat-datepicker #mondaypick></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="week-picker" appearance="fill">
      <mat-label>Vasárnap</mat-label>
      <input [(ngModel)]="sunday" matInput disabled [matDatepicker]="sundaypick">
      <mat-datepicker-toggle matSuffix [for]="sundaypick"></mat-datepicker-toggle>
      <mat-datepicker #sundaypick></mat-datepicker>
    </mat-form-field>

    <button class="arrow-button" (click)="changeIterators('inc')" mat-raised-button color="primary"><i
      class="material-icons">chevron_right</i></button>
  </div>

  <div *ngIf="monthInterval">
    <mat-form-field appearance="fill">
      <mat-label>Hónap első és utolsó napja</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input [(ngModel)]="firstDateOfMonth"
               (ngModelChange)="getDataByMonth();"
               matStartDate>
        <input [(ngModel)]="lastDateOfMonth"
               (ngModelChange)="getDataByMonth();"
               matEndDate>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
</div>
<div class="spacer"></div>


<div class="" >

  <div class="new-model-component section-1 other-section">
    <h2>Kis autók</h2>
    <div class="spacer"></div>
    <div id="chart1"></div>
  </div>



  <div class="new-model-component section-1 other-section">
    <h2>Közepes autók</h2>
    <div class="new-model-component section-1 position-sticky top-0">
      <!--<h1>Foglalások</h1>
      <div class="spacer"></div>-->

      <button *ngIf="dayInterval" class="interval-button" mat-raised-button color="primary">Napi</button>
      <button *ngIf="weekInterval || monthInterval"
              (click)="dayInterval = true; weekInterval = false; monthInterval = false"
              class="interval-button" mat-raised-button color="basic">Napi
      </button>

      <button *ngIf="weekInterval" class="interval-button" mat-raised-button color="primary">Heti</button>
      <button *ngIf="dayInterval || monthInterval"
              (click)="dayInterval = false; weekInterval = true; monthInterval = false"
              class="interval-button" mat-raised-button color="basic">Heti
      </button>

      <button *ngIf="monthInterval" class="interval-button" mat-raised-button color="primary">Havi</button>
      <button *ngIf="dayInterval || weekInterval"
              (click)="dayInterval = false; weekInterval = false; monthInterval = true"
              class="interval-button" mat-raised-button color="basic">Havi
      </button>

      <div class="spacer"></div>

      <div *ngIf="dayInterval">
        <mat-form-field class="week-picker" appearance="fill">
          <mat-label>Választott nap</mat-label>
          <input [(ngModel)]="today" (ngModelChange)="getDataByDay(today);" matInput [matDatepicker]="todaypick">
          <mat-datepicker-toggle matSuffix [for]="todaypick"></mat-datepicker-toggle>
          <mat-datepicker #todaypick></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="weekInterval">
        <button class="arrow-button" (click)="changeIterators('dec')" mat-raised-button color="primary"><i
          class="material-icons">chevron_left</i></button>

        <mat-form-field class="week-picker" appearance="fill">
          <mat-label>Hétfő</mat-label>
          <input [(ngModel)]="monday" matInput disabled [matDatepicker]="mondaypick">
          <mat-datepicker-toggle matSuffix [for]="mondaypick"></mat-datepicker-toggle>
          <mat-datepicker #mondaypick></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="week-picker" appearance="fill">
          <mat-label>Vasárnap</mat-label>
          <input [(ngModel)]="sunday" matInput disabled [matDatepicker]="sundaypick">
          <mat-datepicker-toggle matSuffix [for]="sundaypick"></mat-datepicker-toggle>
          <mat-datepicker #sundaypick></mat-datepicker>
        </mat-form-field>

        <button class="arrow-button" (click)="changeIterators('inc')" mat-raised-button color="primary"><i
          class="material-icons">chevron_right</i></button>
      </div>

      <div *ngIf="monthInterval">
        <mat-form-field appearance="fill">
          <mat-label>Hónap első és utolsó napja</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input [(ngModel)]="firstDateOfMonth"
                   (ngModelChange)="getDataByMonth();"
                   matStartDate>
            <input [(ngModel)]="lastDateOfMonth"
                   (ngModelChange)="getDataByMonth();"
                   matEndDate>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <div class="spacer"></div>
    <div id="chart2"></div>
  </div>



  <div class="new-model-component section-1 other-section">
    <h2>Egyterű autók</h2>
    <div class="new-model-component section-1 position-sticky top-0">
      <!--<h1>Foglalások</h1>
      <div class="spacer"></div>-->

      <button *ngIf="dayInterval" class="interval-button" mat-raised-button color="primary">Napi</button>
      <button *ngIf="weekInterval || monthInterval"
              (click)="dayInterval = true; weekInterval = false; monthInterval = false"
              class="interval-button" mat-raised-button color="basic">Napi
      </button>

      <button *ngIf="weekInterval" class="interval-button" mat-raised-button color="primary">Heti</button>
      <button *ngIf="dayInterval || monthInterval"
              (click)="dayInterval = false; weekInterval = true; monthInterval = false"
              class="interval-button" mat-raised-button color="basic">Heti
      </button>

      <button *ngIf="monthInterval" class="interval-button" mat-raised-button color="primary">Havi</button>
      <button *ngIf="dayInterval || weekInterval"
              (click)="dayInterval = false; weekInterval = false; monthInterval = true"
              class="interval-button" mat-raised-button color="basic">Havi
      </button>

      <div class="spacer"></div>

      <div *ngIf="dayInterval">
        <mat-form-field class="week-picker" appearance="fill">
          <mat-label>Választott nap</mat-label>
          <input [(ngModel)]="today" (ngModelChange)="getDataByDay(today);" matInput [matDatepicker]="todaypick">
          <mat-datepicker-toggle matSuffix [for]="todaypick"></mat-datepicker-toggle>
          <mat-datepicker #todaypick></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="weekInterval">
        <button class="arrow-button" (click)="changeIterators('dec')" mat-raised-button color="primary"><i
          class="material-icons">chevron_left</i></button>

        <mat-form-field class="week-picker" appearance="fill">
          <mat-label>Hétfő</mat-label>
          <input [(ngModel)]="monday" matInput disabled [matDatepicker]="mondaypick">
          <mat-datepicker-toggle matSuffix [for]="mondaypick"></mat-datepicker-toggle>
          <mat-datepicker #mondaypick></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="week-picker" appearance="fill">
          <mat-label>Vasárnap</mat-label>
          <input [(ngModel)]="sunday" matInput disabled [matDatepicker]="sundaypick">
          <mat-datepicker-toggle matSuffix [for]="sundaypick"></mat-datepicker-toggle>
          <mat-datepicker #sundaypick></mat-datepicker>
        </mat-form-field>

        <button class="arrow-button" (click)="changeIterators('inc')" mat-raised-button color="primary"><i
          class="material-icons">chevron_right</i></button>
      </div>

      <div *ngIf="monthInterval">
        <mat-form-field appearance="fill">
          <mat-label>Hónap első és utolsó napja</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input [(ngModel)]="firstDateOfMonth"
                   (ngModelChange)="getDataByMonth();"
                   matStartDate>
            <input [(ngModel)]="lastDateOfMonth"
                   (ngModelChange)="getDataByMonth();"
                   matEndDate>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <div class="spacer"></div>
    <div id="chart3"></div>
  </div>



  <div class="new-model-component section-1 other-section">
    <h2>Mikrobuszok</h2>
    <div class="new-model-component section-1 position-sticky top-0">
      <!--<h1>Foglalások</h1>
      <div class="spacer"></div>-->

      <button *ngIf="dayInterval" class="interval-button" mat-raised-button color="primary">Napi</button>
      <button *ngIf="weekInterval || monthInterval"
              (click)="dayInterval = true; weekInterval = false; monthInterval = false"
              class="interval-button" mat-raised-button color="basic">Napi
      </button>

      <button *ngIf="weekInterval" class="interval-button" mat-raised-button color="primary">Heti</button>
      <button *ngIf="dayInterval || monthInterval"
              (click)="dayInterval = false; weekInterval = true; monthInterval = false"
              class="interval-button" mat-raised-button color="basic">Heti
      </button>

      <button *ngIf="monthInterval" class="interval-button" mat-raised-button color="primary">Havi</button>
      <button *ngIf="dayInterval || weekInterval"
              (click)="dayInterval = false; weekInterval = false; monthInterval = true"
              class="interval-button" mat-raised-button color="basic">Havi
      </button>

      <div class="spacer"></div>

      <div *ngIf="dayInterval">
        <mat-form-field class="week-picker" appearance="fill">
          <mat-label>Választott nap</mat-label>
          <input [(ngModel)]="today" (ngModelChange)="getDataByDay(today);" matInput [matDatepicker]="todaypick">
          <mat-datepicker-toggle matSuffix [for]="todaypick"></mat-datepicker-toggle>
          <mat-datepicker #todaypick></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="weekInterval">
        <button class="arrow-button" (click)="changeIterators('dec')" mat-raised-button color="primary"><i
          class="material-icons">chevron_left</i></button>

        <mat-form-field class="week-picker" appearance="fill">
          <mat-label>Hétfő</mat-label>
          <input [(ngModel)]="monday" matInput disabled [matDatepicker]="mondaypick">
          <mat-datepicker-toggle matSuffix [for]="mondaypick"></mat-datepicker-toggle>
          <mat-datepicker #mondaypick></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="week-picker" appearance="fill">
          <mat-label>Vasárnap</mat-label>
          <input [(ngModel)]="sunday" matInput disabled [matDatepicker]="sundaypick">
          <mat-datepicker-toggle matSuffix [for]="sundaypick"></mat-datepicker-toggle>
          <mat-datepicker #sundaypick></mat-datepicker>
        </mat-form-field>

        <button class="arrow-button" (click)="changeIterators('inc')" mat-raised-button color="primary"><i
          class="material-icons">chevron_right</i></button>
      </div>

      <div *ngIf="monthInterval">
        <mat-form-field appearance="fill">
          <mat-label>Hónap első és utolsó napja</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input [(ngModel)]="firstDateOfMonth"
                   (ngModelChange)="getDataByMonth();"
                   matStartDate>
            <input [(ngModel)]="lastDateOfMonth"
                   (ngModelChange)="getDataByMonth();"
                   matEndDate>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <div class="spacer"></div>
    <div id="chart4"></div>
  </div>



  <div class="new-model-component section-1 other-section">
    <h2>Teherautók</h2>
    <div class="new-model-component section-1 position-sticky top-0">
      <!--<h1>Foglalások</h1>
      <div class="spacer"></div>-->

      <button *ngIf="dayInterval" class="interval-button" mat-raised-button color="primary">Napi</button>
      <button *ngIf="weekInterval || monthInterval"
              (click)="dayInterval = true; weekInterval = false; monthInterval = false"
              class="interval-button" mat-raised-button color="basic">Napi
      </button>

      <button *ngIf="weekInterval" class="interval-button" mat-raised-button color="primary">Heti</button>
      <button *ngIf="dayInterval || monthInterval"
              (click)="dayInterval = false; weekInterval = true; monthInterval = false"
              class="interval-button" mat-raised-button color="basic">Heti
      </button>

      <button *ngIf="monthInterval" class="interval-button" mat-raised-button color="primary">Havi</button>
      <button *ngIf="dayInterval || weekInterval"
              (click)="dayInterval = false; weekInterval = false; monthInterval = true"
              class="interval-button" mat-raised-button color="basic">Havi
      </button>

      <div class="spacer"></div>

      <div *ngIf="dayInterval">
        <mat-form-field class="week-picker" appearance="fill">
          <mat-label>Választott nap</mat-label>
          <input [(ngModel)]="today" (ngModelChange)="getDataByDay(today);" matInput [matDatepicker]="todaypick">
          <mat-datepicker-toggle matSuffix [for]="todaypick"></mat-datepicker-toggle>
          <mat-datepicker #todaypick></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="weekInterval">
        <button class="arrow-button" (click)="changeIterators('dec')" mat-raised-button color="primary"><i
          class="material-icons">chevron_left</i></button>

        <mat-form-field class="week-picker" appearance="fill">
          <mat-label>Hétfő</mat-label>
          <input [(ngModel)]="monday" matInput disabled [matDatepicker]="mondaypick">
          <mat-datepicker-toggle matSuffix [for]="mondaypick"></mat-datepicker-toggle>
          <mat-datepicker #mondaypick></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="week-picker" appearance="fill">
          <mat-label>Vasárnap</mat-label>
          <input [(ngModel)]="sunday" matInput disabled [matDatepicker]="sundaypick">
          <mat-datepicker-toggle matSuffix [for]="sundaypick"></mat-datepicker-toggle>
          <mat-datepicker #sundaypick></mat-datepicker>
        </mat-form-field>

        <button class="arrow-button" (click)="changeIterators('inc')" mat-raised-button color="primary"><i
          class="material-icons">chevron_right</i></button>
      </div>

      <div *ngIf="monthInterval">
        <mat-form-field appearance="fill">
          <mat-label>Hónap első és utolsó napja</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input [(ngModel)]="firstDateOfMonth"
                   (ngModelChange)="getDataByMonth();"
                   matStartDate>
            <input [(ngModel)]="lastDateOfMonth"
                   (ngModelChange)="getDataByMonth();"
                   matEndDate>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <div class="spacer"></div>
    <div id="chart5"></div>
  </div>


</div>
