import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import Swal from 'sweetalert2';
// @ts-ignore
import moment = require('moment/moment');
import {AppService} from '../app.service';

export interface RentData {
  id: string;
  rent_start: string;
  rent_end: string;
  renter_id: string;
  deposit: string;
  car_plate: string;
  kmh_state_before: string;
  kmh_state_after: string;
  takeaway_start: string;
  takeaway_end: string;
  finished: string;
}

@Component({
  selector: 'app-finished-rents',
  templateUrl: './finished-rents.component.html',
  styleUrls: ['./finished-rents.component.css']
})
export class FinishedRentsComponent implements OnInit {

  rentToUpdate: any;
  updateRentPage = false;
  rents: any;
  listLength = 0;
  filterValue = '';
  list = [];

  displayedColumns: string[] = ['id', 'rent_start', 'rent_end', 'renter_id', 'deposit', 'car_plate', 'kmh_state_before', 'kmh_state_after', 'takeaway_start', 'takeaway_end', 'note'];
  dataSource: MatTableDataSource<RentData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private service: AppService
  ) {
  }

  ngOnInit(): void {
    this.service.getListByModel('rent').subscribe(rents => {
      rents.forEach(rent => {
        if (rent.finished === 1) {
          this.list.push(rent);
        }
      });
      this.listLength = this.list.length;
      this.dataSource = new MatTableDataSource(this.list);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(): void {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateRent(rent): void {
    if (rent.finished === 1) {
      Swal.fire({
        title: 'Ez a bérlés már lezárt állapotú. Biztos, hogy szerkeszteni akarod?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Igen',
        cancelButtonText: 'Nem'
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateRentPage = true;
          this.rentToUpdate = rent;
          if (rent.kmh_state_before !== rent.kmh_state_after) {
            this.rentToUpdate.kmh_state_after = rent.kmh_state_before;
          }
          this.rentToUpdate.rent_start = moment(this.rentToUpdate.rent_start.replace(' ', 'T'));
          this.rentToUpdate.rent_end = moment(this.rentToUpdate.rent_end.replace(' ', 'T'));
          this.rentToUpdate.takeaway_start = moment(this.rentToUpdate.takeaway_start.replace(' ', 'T'));
          this.rentToUpdate.takeaway_end = moment(this.rentToUpdate.takeaway_end.replace(' ', 'T'));
        }
      });
    } else {
      this.updateRentPage = true;
      this.rentToUpdate = rent;
      if (rent.kmh_state_before !== rent.kmh_state_after) {
        this.rentToUpdate.kmh_state_after = rent.kmh_state_before;
      }
      this.rentToUpdate.rent_start = moment(this.rentToUpdate.rent_start.replace(' ', 'T'));
      this.rentToUpdate.rent_end = moment(this.rentToUpdate.rent_end.replace(' ', 'T'));
      this.rentToUpdate.takeaway_start = moment(this.rentToUpdate.takeaway_start.replace(' ', 'T'));
      this.rentToUpdate.takeaway_end = moment(this.rentToUpdate.takeaway_end.replace(' ', 'T'));
    }
  }

}
