import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-today',
  templateUrl: './today.component.html',
  styleUrls: ['./today.component.css']
})
export class TodayComponent implements OnInit {

  events = [];

  constructor(
    public service: AppService,
    public router: Router) {
  }

  ngOnInit(): void {
    this.service.getToday().subscribe(res => {
      this.events = res.data;
      console.log(this.events);
    });
  }

  navigateToRent(rentId): void {
    this.router.navigate(['berles-szerkesztes/' + rentId]);
  }

}
