import {Component, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../app.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';

export interface LogTable {
  user: string;
  name: number;
  data: number;
}

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {

  displayedColumns: string[] = ['user', 'name', 'data'];
  dataSource: MatTableDataSource<LogTable>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  pageSize = 10;
  page = 1;
  maxPage = 0;
  logs = 0;

  constructor(public service: AppService) {
  }

  ngOnInit(): void {
    this.getLogs(this.pageSize, this.page);
  }

  getLogs(take, page): void {
    this.service.getLogs(take, page).subscribe(res => {
      this.dataSource = res.data.list;
      this.maxPage = res.data.meta.last_page;
      this.logs = res.data.meta.total;
    });
  }

  navigateLeft(): void {
    if (this.page - 1 === 0) {
      this.page = this.maxPage;
      this.getLogs(this.pageSize, this.page);
    } else {
      this.page = this.page - 1;
      this.getLogs(this.pageSize, this.page);
    }
  }

  navigateRight(): void {
    if (this.page + 1 > this.maxPage) {
      this.page =  1;
      this.getLogs(this.pageSize, this.page);
    } else {
      this.page = this.page + 1;
      this.getLogs(this.pageSize, this.page);
    }
  }
}



