import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../app.service';
import {Renter} from '../models/renter.model';
import Swal from 'sweetalert2';
import {Rent} from '../models/rent.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-quick-rent',
  templateUrl: './quick-rent.component.html',
  styleUrls: ['./quick-rent.component.css']
})
export class QuickRentComponent implements OnInit {

  quickRentForm = new FormGroup({
    filterCar: new FormControl(''),
    deposit: new FormControl(''),
    carPlate: new FormControl('', Validators.required),
    rentStart: new FormControl('', Validators.required),
    rentEnd: new FormControl('', Validators.required),
  });

  oldRenter = new Renter();
  newRenter = new Renter();

  rent = new Rent();
  renters = [];
  cars = [];

  userAlreadyCreated = 0;

  filteredRenters = [];
  filteredCars = [];
  idNumberFilter = '';
  carFilter = '';
  deposit = 0;

  tempRentStart: any;
  tempRentEnd: any;

  constructor(
    private service: AppService,
    private router: Router
  ) {
  }


  ngOnInit(): void {
    this.oldRenter.id_number = ' ';
    this.service.getListByModel('renter').subscribe(renters => {
      this.filteredRenters = renters;
      this.renters = renters;
    });

    this.service.getRentableCars().subscribe(cars => {
      this.filteredCars = cars;
      this.cars = cars;
    });
  }

  onSubmit(): void {
    console.warn(this.quickRentForm.value);
  }

  createQuickRent(): void {
    const yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1);

    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);

    if (!this.rent.renter_id && (!this.newRenter.name || !this.newRenter.phone)) {
      Swal.fire('Add meg a hiányzó adatokat!', '', 'error');
      // @ts-ignore
    } else if (this.rent.rent_start > this.rent.rent_end) {
      Swal.fire('A foglalás végé korábban van a foglalás kezdeténél!', '', 'error');
      // @ts-ignore
    } else if (!this.quickRentForm.valid) {
      Swal.fire('Add meg a hiányzó adatokat!', '', 'error');
    } else {

      if (this.quickRentForm.status === 'VALID') {
        this.saveTemporaryDates();
        this.rent.rent_start = this.momentDateConverter(this.rent.rent_start);
        this.rent.rent_end = this.momentDateConverter(this.rent.rent_end);
        this.rent.takeaway_start = this.momentDateConverter(this.rent.takeaway_start);
        this.rent.takeaway_end = this.momentDateConverter(this.rent.takeaway_end);
        this.rent.finished = 0;
        this.rent.deleted = 0;

        // @ts-ignore
        this.rent.deposit = this.deposit;

        this.newRenter.deleted = 0;
        if (!this.newRenter.name && !this.newRenter.phone) {
          this.userAlreadyCreated = 1;
        }
        if (this.userAlreadyCreated !== 1) {
          this.service.createModel(this.newRenter, 'renter').subscribe(createRenter => {
            this.userAlreadyCreated = 1;
            this.rent.renter_id = createRenter.data.id;
            this.createRent();
          });
        } else {
          this.createRent();
        }

      } else {
        Swal.fire('Töltsd ki a kötelező mezőket!', '', 'warning');
      }
    }
  }

  momentDateConverter(date): string {
    if (!date || typeof date === 'string') {
      return;
    }

    if (date.year === undefined) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      let hours = date.getHours();
      let minutes = date.getMinutes();

      if (hours < 10) {
        hours = '0' + hours;
      }

      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00';
    } else {
      const year = date.year();
      const month = date.month() + 1;
      const day = date.date();

      let hours = date.hour();
      let minutes = date.minute();

      if (hours < 10) {
        hours = '0' + hours;
      }

      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00';
    }
  }
  saveTemporaryDates(): void {
    this.tempRentStart = this.rent.rent_start;
    this.tempRentEnd = this.rent.rent_end;
  }

  loadTemporaryDates(): void {
    this.rent.rent_start = this.tempRentStart;
    this.rent.rent_end = this.tempRentEnd;
  }

  filterIds(filterId): void {
    this.filteredRenters = [];
    this.renters.forEach(renter => {
      const renterNameWithId = renter.name + renter.id_number;
      if (renterNameWithId && renterNameWithId.toLowerCase().includes(filterId.toLowerCase())) {
        this.filteredRenters.push(renter);
      }
    });
  }

  filterCars(filterCar): void {
    this.filteredCars = [];
    this.cars.forEach(car => {
      const carPlateWithBrand = car.plate + car.brand;
      if (carPlateWithBrand && carPlateWithBrand.toLowerCase().includes(filterCar.toLowerCase())) {
        this.filteredCars.push(car);
      }
    });
  }

  setKmhBeforeState(): void {
    const selectedCar = this.cars.filter(car => car.plate === this.rent.car_plate);
    if (selectedCar.length !== 0) {
      this.rent.kmh_state_before = selectedCar[0].kmh_state;
    }
  }

  createRent(): void {
    this.service.createModel(this.rent, 'rent').subscribe(res => {
      if (res === 1) {
        Swal.fire('Foglalás sikeresen rögzítve!', '', 'success');
        this.quickRentForm.reset();
        this.router.navigate(['/berlesek']);
      } else {
        this.loadTemporaryDates();
        let msg = '';
        res.forEach(rent => {
          msg = msg.concat(rent.rent_start + '\n - \n ' + rent.rent_end + '\n\n');
        });
        Swal.fire('A foglalás ütközik az alábbi bérlésekkel: \n\n' + msg, '', 'warning');
      }
    });
  }

  setTimeNow(takeaway): void {
    // @ts-ignore
    this.rent[takeaway] = new Date();
  }

}
