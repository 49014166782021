<div class="new-model-component">
  <h1>Figyelmeztetések</h1>


  <div class="container">
    <div class="row">
      <div class="warning-wrapper col-12 col-md-6" *ngFor="let warning of warnings">
        <div class="warning" (click)="navToCarUpdate(warning.car)">
          <p>{{warning.car}}</p>
          <div class="line"></div>
          <p>{{checkCarDocumentExpiration(warning['is-car-document-in-expiration-period'])}}</p>
          <p>{{checkCarDocument(warning['has-car-document-expired'])}}</p>
          <p>{{checkVignette(warning['vignette-has-expired'])}}</p>
          <p>{{checkService(warning['is-service-near'])}}</p>
          <p>{{checkCarReturned(warning['rent-expired-not-returned'])}}</p>
        </div>
      </div>
    </div>
  </div>

</div>
