import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {Observable} from 'rxjs';

import Swal from 'sweetalert2';
import {Router} from '@angular/router';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexPlotOptions,
  ApexXAxis,
  ApexFill,
  ApexDataLabels,
  ApexYAxis,
  ApexGrid
} from 'ng-apexcharts';
import * as ApexCharts from 'apexcharts';
import * as moment from 'moment';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'app-summarize2',
  templateUrl: './summarize2.component.html',
  styleUrls: ['./summarize2.component.css']
})
export class SummarizeComponent2 implements OnInit {

  getRents = new Observable();
  getFreeCars = new Observable();
  rents: any;
  cars: any;
  chartStart: Date;
  chartEnd: Date;

  chartKis;
  chartKozep;
  chartEgyter;
  chartMikro;
  chartTeher;

  rentsWithSmallFreeCars = [];
  rentsWithMidFreeCars = [];
  rentsWithOneSpaceFreeCars = [];
  rentsWithMicroBusFreeCars = [];
  rentsWithTruckFreeCars = [];

  allRents = {
    kis: [],
    közép: [],
    egyterű: [],
    mikrobusz: [],
    teherautó: [],
    option: 0,
    maxdates: {start: new Date(), end: new Date()}
  };

  currentDateForMonday: Date;
  currentDateForSunday: Date;
  today: moment.Moment;
  monday: Date;
  sunday: Date;
  carDateStart: Date;
  carDateEnd: Date;
  firstDateOfMonth: Date;
  lastDateOfMonth: Date;

  dayInterval = false;
  weekInterval = true;
  monthInterval = false;

  options: any;

  data = [];
  ganttOptions: any;
  minDate: any;
  maxDate: any;

  small = true;
  mid = false;
  onespace = false;
  microbus = false;
  cargo = false;

  constructor(public service: AppService,
              public router: Router) {
  }

  drawChart(chartSeries, chartSelector: string, size: number): void {
    const tooltip = {
      // tslint:disable-next-line:typedef
      custom({series, seriesIndex, dataPointIndex, w}) {
        const rent = chartSeries[seriesIndex].data[dataPointIndex].rent;
        // console.log(rent);
        return '<div class="arrow_box">' +
          // '<span>' + ' id: ' + rent.id + '</span>' + '<br>' +
          '<span>' + ' név: ' + rent.renter.name + '</span>' + '<br>' +
          '<span>' + ' telefonszám: ' + rent.renter.phone + '</span>' + '<br>' +
          '<span>' + ' kezdés: ' + rent.rent_start + '</span>' + '<br>' +
          '<span>' + ' vége: ' + rent.rent_end + '</span>' + '<br>' +
          '<span>' + ' rendszám: ' + rent.car_plate + '</span>' + '<br>' +
          '<span>' + ' autó: ' + rent.car.brand + '</span>' +

          '</div>';
      }
    };

    if (size < 3) {
      size = 3;
    }
    const defSeries = [
      {
        name: 'Bob',
        data: [
          {
            x: 'Ford Fiesta',
            y: [
              new Date('2022-07-02').getTime(),
              new Date('2022-07-04').getTime()
            ]
          },
        ]
      },
      {
        name: 'Joe',
        data: [
          {
            x: 'Ford Fiesta',
            y: [
              new Date('2022-07-05').getTime(),
              new Date('2022-07-06').getTime()
            ]
          },
          {
            x: 'Audi',
            y: [
              new Date('2022-07-09').getTime(),
              new Date('2022-07-11').getTime()
            ]
          },
        ]
      },
    ];
    if (chartSeries == null) {
      chartSeries = defSeries;
    }
    // console.log(this.chartStart);
    // console.log(this.chartEnd);
    const options = {
      chart: {
        height: 50 * size,
        events: {
          // tslint:disable-next-line:typedef
          click: function(event, chartContext, config) {
            const rent = chartSeries[config.seriesIndex].data[config.dataPointIndex].rent;
            this.getRentById(rent.id);
          }.bind(this)
        },
        type: 'rangeBar'
      },
      series: chartSeries,
      xaxis: {
        type: 'datetime',
        tickAmount: 1,
        min: this.chartStart.getTime(),
        max: this.chartEnd.getTime()
      },
      plotOptions: {
        bar: {
          horizontal: true,
          rangeBarGroupRows: true
        }
      },
      tooltip

    };
    // @ts-ignore
    switch (chartSelector) {
      case '#chart1':
        if (this.chartKis !== undefined){
          this.chartKis.destroy();
        }
        if (chartSeries.length > 0){
          this.chartKis = new ApexCharts(document.querySelector(chartSelector), options);
          this.chartKis.render();
        }
        break;
      case '#chart2':
        if (this.chartKozep !== undefined){
          this.chartKozep.destroy();
        }
        if (chartSeries.length > 0) {
          this.chartKozep = new ApexCharts(document.querySelector(chartSelector), options);
          this.chartKozep.render();
        }
        break;
      case '#chart3':
        if (this.chartEgyter !== undefined){
          this.chartEgyter.destroy();
        }
        if (chartSeries.length > 0) {
          this.chartEgyter = new ApexCharts(document.querySelector(chartSelector), options);
          this.chartEgyter.render();
        }
        break;
      case '#chart4':
        if (this.chartMikro !== undefined){
          this.chartMikro.destroy();
        }
        if (chartSeries.length > 0) {
          this.chartMikro = new ApexCharts(document.querySelector(chartSelector), options);
          this.chartMikro.render();
        }
        break;
      case '#chart5':
        if (this.chartTeher !== undefined){
          this.chartTeher.destroy();
        }
        if (chartSeries.length > 0) {
          this.chartTeher = new ApexCharts(document.querySelector(chartSelector), options);
          this.chartTeher.render();
        }
        break;
    }

  }

  ngOnInit(): void {
    this.today = moment();

    this.currentDateForMonday = new Date();
    this.currentDateForSunday = new Date();

    this.monday = new Date(this.currentDateForMonday.setDate(this.currentDateForMonday.getDate() - this.currentDateForMonday.getDay() + 1));
    this.sunday = new Date(this.currentDateForSunday.setDate(this.currentDateForSunday.getDate() - this.currentDateForSunday.getDay() + 7));

    this.carDateStart = new Date(this.monday.getFullYear(), this.monday.getMonth(), this.monday.getDate(), 0, 1);
    this.carDateEnd = new Date(this.monday.getFullYear(), this.monday.getMonth(), this.monday.getDate(), 0, 2);

    this.getRents = this.service.getRentsByDate(this.dateConverter(this.monday), this.dateConverter(this.sunday));
    this.getDataByWeek(this.carDateStart, this.carDateEnd);

  }

  datetimeConverter(date, mode): string {
    date = new Date(date);

    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (mode === 1) {
      return year + '-' + month + '-' + day + '&nbsp;' + hours + ':' + minutes;
    } else if (mode === 2) {
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
    }
  }

  dateConverter(date): string {
    date = new Date(date);

    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return year + '-' + month + '-' + day;
  }

  changeIterators(change): void {
    if (change === 'dec') {
      // tslint:disable-next-line:max-line-length
      this.monday = new Date(this.currentDateForMonday.setDate(this.currentDateForMonday.getDate() + 1 - this.currentDateForMonday.getDay() - 7));
      // tslint:disable-next-line:max-line-length
      this.sunday = new Date(this.currentDateForSunday.setDate(this.currentDateForSunday.getDate() - this.currentDateForSunday.getDay() - 7));
      this.carDateStart = new Date(this.monday.getFullYear(), this.monday.getMonth(), this.monday.getDate(), 0, 1);
      this.carDateEnd = new Date(this.monday.getFullYear(), this.monday.getMonth(), this.monday.getDate(), 0, 2);
    } else if (change === 'inc') {
      // tslint:disable-next-line:max-line-length
      this.monday = new Date(this.currentDateForMonday.setDate(this.currentDateForMonday.getDate() + 1 - this.currentDateForMonday.getDay() + 7));
      // tslint:disable-next-line:max-line-length
      this.sunday = new Date(this.currentDateForSunday.setDate(this.currentDateForSunday.getDate() - this.currentDateForSunday.getDay() + 7));
      this.carDateStart = new Date(this.monday.getFullYear(), this.monday.getMonth(), this.monday.getDate(), 0, 1);
      this.carDateEnd = new Date(this.monday.getFullYear(), this.monday.getMonth(), this.monday.getDate(), 0, 2);
    }
    this.getDataByWeek(this.carDateStart, this.carDateEnd);
  }

  getDataByMonth(): void {
    this.rentsWithSmallFreeCars = [];
    this.rentsWithMidFreeCars = [];
    this.rentsWithOneSpaceFreeCars = [];
    this.rentsWithMicroBusFreeCars = [];
    this.rentsWithTruckFreeCars = [];

    // @ts-ignore
    const firstDay = new Date(this.firstDateOfMonth.year(), this.firstDateOfMonth.month(), this.firstDateOfMonth.date());
    // @ts-ignore
    const lastDay = new Date(this.lastDateOfMonth.year(), this.lastDateOfMonth.month(), this.lastDateOfMonth.date());
    // @ts-ignore
    const carDateStart = new Date(this.firstDateOfMonth.year(), this.firstDateOfMonth.month(), this.firstDateOfMonth.date(), 0, 1);
    // @ts-ignore
    const carDateEnd = new Date(this.firstDateOfMonth.year(), this.firstDateOfMonth.month(), this.firstDateOfMonth.date(), 0, 2);

    this.getRents = this.service.getRentsByDate(this.dateConverter(firstDay), this.dateConverter(lastDay));
    // @ts-ignore
    this.allRents.option = {
      width: 1800,
      height: 500,
      hAxis: {format: 'MM. dd.'}
    };
    // @ts-ignore
    this.allRents.maxdates.start = firstDay.setHours(0, 0);
    // @ts-ignore
    this.allRents.maxdates.end = lastDay.setHours(23, 59);
    this.chartStart = new Date(firstDay.setHours(0, 0));
    this.chartEnd = new Date(lastDay.setHours(23, 59));
    this.getSummedRents();
  }

  getDataByWeek(carStart, carEnd): void {
    this.rentsWithSmallFreeCars = [];
    this.rentsWithMidFreeCars = [];
    this.rentsWithOneSpaceFreeCars = [];
    this.rentsWithMicroBusFreeCars = [];
    this.rentsWithTruckFreeCars = [];

    this.getRents = this.service.getRentsByDate(this.dateConverter(this.monday), this.dateConverter(this.sunday));
    // @ts-ignore
    this.allRents.option = {
      width: 1550,
      height: 500,
      hAxis: {format: 'EEE (MM. dd.)'}
    };
    // @ts-ignore
    this.allRents.maxdates.start = this.monday.setHours(0, 0);
    // @ts-ignore
    this.allRents.maxdates.end = this.sunday.setHours(23, 59);

    this.chartStart = new Date(this.monday.setHours(0, 0));
    this.chartEnd = new Date(this.sunday.setHours(0, 0));

    this.getSummedRents();
  }

  getDataByDay(selectedDay): void {
    this.rentsWithSmallFreeCars = [];
    this.rentsWithMidFreeCars = [];
    this.rentsWithOneSpaceFreeCars = [];
    this.rentsWithMicroBusFreeCars = [];
    this.rentsWithTruckFreeCars = [];

    let startOfTheDay;
    let endOfTheDay;
    if (selectedDay && selectedDay.year) {
      startOfTheDay = new Date(selectedDay.year(), selectedDay.month(), selectedDay.date(), 0, 0);
      endOfTheDay = new Date(selectedDay.year(), selectedDay.month(), selectedDay.date(), 23, 59);
      this.carDateStart = new Date(selectedDay.year(), selectedDay.month(), selectedDay.date(), 0, 1);
      this.carDateEnd = new Date(selectedDay.year(), selectedDay.month(), selectedDay.date(), 0, 2);
    } else {
      startOfTheDay = new Date(selectedDay.getFullYear(), selectedDay.getMonth() + 1, selectedDay.getDate());
      endOfTheDay = new Date(selectedDay.getFullYear(), selectedDay.getMonth() + 1, selectedDay.getDate());

      this.carDateStart = new Date(selectedDay.getFullYear(), selectedDay.getMonth() + 1, selectedDay.getDate(), 0, 1);
      this.carDateEnd = new Date(selectedDay.getFullYear(), selectedDay.getMonth() + 1, selectedDay.getDate(), 0, 2);
    }
    this.getRents = this.service.getRentsByDate(this.dateConverter(startOfTheDay), this.dateConverter(endOfTheDay));
    // @ts-ignore
    this.allRents.option = {
      width: 1800,
      height: 500,
      hAxis: {format: ['HH:mm', 'ha']}
    };
    this.allRents.maxdates.start = startOfTheDay.setHours(0, 0);
    this.allRents.maxdates.end = endOfTheDay.setHours(23, 59);
    this.chartStart = new Date(startOfTheDay.setHours(0, 0));
    this.chartEnd = new Date(endOfTheDay.setHours(23, 59));

    this.getSummedRents();
  }

  getSummedRents(): void {
    const mapSeriesKis = new Map<string, any>();
    const mapSeriesKozep = new Map<string, any>();
    const mapSeriesEgyteru = new Map<string, any>();
    const mapSeriesMikro = new Map<string, any>();
    const mapSeriesTeher = new Map<string, any>();
    const kisN: [string] = [''];
    const kozepN: [string] = [''];
    const egyterN: [string] = [''];
    const mikroN: [string] = [''];
    const teherN: [string] = [''];

    this.getRents.subscribe(rents => {
      this.rents = rents;
      this.rents.forEach(rent => {

        const newDates = {
          rent,
          x: rent.car.brand + ' : ' + rent.car_plate,
          y: [
            new Date(rent.rent_start.split(' ')[0]).getTime(),
            new Date(rent.rent_end.split(' ')[0]).getTime()
          ]
        };
        const datas = [];
        datas.push(newDates);

        if (rent.car.category_size.includes('kis')) {
          if (!kisN.includes(rent.car_plate)) {
            kisN.push(rent.car_plate);
          }
          const thisSerie = mapSeriesKis.get(rent.renter.id);
          if (thisSerie === undefined) {
            const newSerie = {
              rent_id: rent.id,
              name: rent.renter.name,
              data: datas
            };
            mapSeriesKis.set(rent.renter.id, newSerie);
          } else {
            thisSerie.data.push(newDates);
          }
        } else if (rent.car.category_size.includes('közép')) {
          if (!kozepN.includes(rent.car_plate)) {
            kozepN.push(rent.car_plate);
          }
          const thisSerie = mapSeriesKozep.get(rent.renter.id);
          if (thisSerie === undefined) {
            const newSerie = {
              name: rent.renter.name,
              data: datas
            };
            mapSeriesKozep.set(rent.renter.id, newSerie);
          } else {
            thisSerie.data.push(newDates);
          }
        } else if (rent.car.category_size.includes('egyterű')) {
          if (!egyterN.includes(rent.car_plate)) {
            egyterN.push(rent.car_plate);
          }
          const thisSerie = mapSeriesEgyteru.get(rent.renter.id);
          if (thisSerie === undefined) {
            const newSerie = {
              name: rent.renter.name,
              data: datas
            };
            mapSeriesEgyteru.set(rent.renter.id, newSerie);
          } else {
            thisSerie.data.push(newDates);
          }
        } else if (rent.car.category_size.includes('mikrobusz')) {
          if (!mikroN.includes(rent.car_plate)) {
            mikroN.push(rent.car_plate);
          }
          const thisSerie = mapSeriesMikro.get(rent.renter.id);
          if (thisSerie === undefined) {
            const newSerie = {
              name: rent.renter.name,
              data: datas
            };
            mapSeriesMikro.set(rent.renter.id, newSerie);
          } else {
            thisSerie.data.push(newDates);
          }
        } else if (rent.car.category_size.includes('teherautó')) {
          if (!teherN.includes(rent.car_plate)) {
            teherN.push(rent.car_plate);
          }
          const thisSerie = mapSeriesTeher.get(rent.renter.id);
          if (thisSerie === undefined) {
            const newSerie = {
              name: rent.renter.name,
              data: datas
            };
            mapSeriesTeher.set(rent.renter.id, newSerie);
          } else {
            thisSerie.data.push(newDates);
          }
        }
      });
      this.drawChart(Array.from(mapSeriesKis.values()), '#chart1', kisN.length);
      this.drawChart(Array.from(mapSeriesKozep.values()), '#chart2', kozepN.length);
      this.drawChart(Array.from(mapSeriesEgyteru.values()), '#chart3', egyterN.length);
      this.drawChart(Array.from(mapSeriesMikro.values()), '#chart4', mikroN.length);
      this.drawChart(Array.from(mapSeriesTeher.values()), '#chart5', teherN.length);

    });


  }

  getRentById(rentId): void {
    this.router.navigate(['berles-szerkesztes/' + rentId]);
  }

}
