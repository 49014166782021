<div *ngIf="!rentCarPage" class="new-model-component">
<h1>Időszakos keresés</h1>
  <mat-icon *ngIf="rentCarPage" class="update-car" (click)="rentCarPage = false; getCarList()">undo</mat-icon>

  <mat-form-field *ngIf="!rentCarPage"  appearance="standard">
    <mat-label>Keresés</mat-label>
    <input [(ngModel)]="filterValue" matInput (keyup)="applyFilter()">
  </mat-form-field>

  <mat-form-field *ngIf="!rentCarPage"  class="search-input" appearance="">
    <mat-label>Keresés kezdete</mat-label>
    <input [(ngModel)]="intervalStartDate" matInput [ngxMatDatetimePicker]="intervalStart" (ngModelChange)="searchInterval()">
    <mat-datepicker-toggle matSuffix [for]="intervalStart"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #intervalStart [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                             [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'"
                             [enableMeridian]="false" [disableMinute]="true" [defaultTime]="[12, 0]" [hideTime]="false">
      <ng-template>
        <span>OK</span>
      </ng-template>
    </ngx-mat-datetime-picker>
  </mat-form-field>

  <mat-form-field *ngIf="!rentCarPage"  class="search-input" appearance="">
    <mat-label>Keresés vége</mat-label>
    <input [(ngModel)]="intervalEndDate" matInput [ngxMatDatetimePicker]="intervalEnd" (ngModelChange)="searchInterval()">
    <mat-datepicker-toggle matSuffix [for]="intervalEnd"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #intervalEnd [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                             [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'"
                             [enableMeridian]="false" [disableMinute]="true" [defaultTime]="[12, 0]" [hideTime]="false">
      <ng-template>
        <span>OK</span>
      </ng-template>
    </ngx-mat-datetime-picker>
  </mat-form-field>

  <div class="mat-elevation-z8" *ngIf="!rentCarPage">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="plate">
        <th class="plate-col" mat-header-cell *matHeaderCellDef mat-sort-header> Rendszám</th>
        <td class="plate-col" mat-cell *matCellDef="let car"> {{car.plate}} </td>
      </ng-container>

      <ng-container matColumnDef="brand">
        <th class="brand-col" mat-header-cell *matHeaderCellDef mat-sort-header> Márka/Típus</th>
        <td class="brand-col" mat-cell *matCellDef="let car"> {{car.brand}} </td>
      </ng-container>

      <ng-container matColumnDef="category_size">
        <th class="cat-col" mat-header-cell *matHeaderCellDef mat-sort-header> Méret</th>
        <td class="cat-col" mat-cell *matCellDef="let car"> {{car.category_size}} </td>
      </ng-container>

      <ng-container matColumnDef="fuel">
        <th class="fuel-col" mat-header-cell *matHeaderCellDef mat-sort-header> Üzemanyag</th>
        <td class="fuel-col" mat-cell *matCellDef="let car"> {{car.fuel}} </td>
      </ng-container>

      <ng-container matColumnDef="kmh_state">
        <th class="kmh-col" mat-header-cell *matHeaderCellDef mat-sort-header> Km óra állás</th>
        <td class="kmh-col" mat-cell *matCellDef="let car"> {{car.kmh_state}} </td>
      </ng-container>

      <ng-container matColumnDef="service_interval">
        <th class="ser-interval-col" mat-header-cell *matHeaderCellDef mat-sort-header> Szervíz intervallum</th>
        <td class="ser-interval-col" mat-cell *matCellDef="let car"> {{car.service_interval}} </td>
      </ng-container>

      <ng-container matColumnDef="vignette_expiration">
        <th class="vig-col" mat-header-cell *matHeaderCellDef mat-sort-header> Autópálya Matrica</th>
        <td class="vig-col" mat-cell *matCellDef="let car"> {{showVignette(car.vignette_expiration)}} </td>
      </ng-container>

      <ng-container matColumnDef="service_date">
        <th class="service-date-col" mat-header-cell *matHeaderCellDef mat-sort-header> Szervíz dátum</th>
        <td class="service-date-col" mat-cell *matCellDef="let car"> {{showServiceDate(car.service_date)}} </td>
      </ng-container>

      <ng-container matColumnDef="car_document_expiration">
        <th class="doc-date-col" mat-header-cell *matHeaderCellDef mat-sort-header> Forgalmi lejár</th>
        <td class="doc-date-col" mat-cell *matCellDef="let car"> {{car.car_document_expiration}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr (click)="rentCar(car)" mat-row *matRowDef="let car; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Keresés eredménytelen az alábbi szűrővel "{{filterValue}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  </div>

</div>

<app-add-rent [carPlate]="carPlate" [kmhStateBefore]="kmhState" [carToRent]="true" *ngIf="rentCarPage"></app-add-rent>
