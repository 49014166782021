<div class="new-model-component">
  <h1>Bérlők</h1>
  <mat-icon *ngIf="updateRenterPage" class="update-car" (click)="updateRenterPage = false; getRentersList()">undo</mat-icon>

  <mat-form-field *ngIf="!updateRenterPage" appearance="standard">
    <mat-label>Keresés</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
  </mat-form-field>

  <div class="mat-elevation-z8" *ngIf="!updateRenterPage">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="id">
        <th class="id-col" mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
        <td class="id-col" mat-cell *matCellDef="let renter"> {{renter.id}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th class="name-col" mat-header-cell *matHeaderCellDef mat-sort-header> Név</th>
        <td class="name-col" mat-cell *matCellDef="let renter"> {{renter.name}} </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th class="phone-col" mat-header-cell *matHeaderCellDef mat-sort-header> Telefonszám</th>
        <td class="phone-col" mat-cell *matCellDef="let renter"> {{renter.phone}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th class="mail-col" mat-header-cell *matHeaderCellDef mat-sort-header> E-mail</th>
        <td class="mail-col" mat-cell *matCellDef="let renter"> {{renter.email}}</td>
      </ng-container>

      <ng-container matColumnDef="mother_name">
        <th class="mother-name-col" mat-header-cell *matHeaderCellDef mat-sort-header> Anyja neve</th>
        <td class="mother-name-col" mat-cell *matCellDef="let renter"> {{renter.mother_name}}</td>
      </ng-container>

      <ng-container matColumnDef="id_number">
        <th class="id-number-col" mat-header-cell *matHeaderCellDef mat-sort-header> Személyi ig. szám</th>
        <td class="id-number-col" mat-cell *matCellDef="let renter"> {{renter.id_number}}</td>
      </ng-container>

      <ng-container matColumnDef="license_type">
        <th class="license-col" mat-header-cell *matHeaderCellDef mat-sort-header> Jogosítvány típusa</th>
        <td class="license-col" mat-cell *matCellDef="let renter"> {{renter.license_type}}</td>
      </ng-container>

      <ng-container matColumnDef="license_number">
        <th class="license-number-col" mat-header-cell *matHeaderCellDef mat-sort-header> Jogosítvány száma</th>
        <td class="license-number-col" mat-cell *matCellDef="let renter"> {{renter.license_number}}</td>
      </ng-container>

      <ng-container matColumnDef="nationality">
        <th class="nation-col" mat-header-cell *matHeaderCellDef mat-sort-header> Nemzetiség</th>
        <td class="nation-col" mat-cell *matCellDef="let renter"> {{renter.nationality}}</td>
      </ng-container>

      <ng-container matColumnDef="payment">
        <th class="payment-col" mat-header-cell *matHeaderCellDef mat-sort-header> Fizetés típusa</th>
        <td class="payment-col" mat-cell *matCellDef="let renter"> {{renter.payment}}</td>
      </ng-container>

      <ng-container matColumnDef="deposit">
        <th class="deposit-col" mat-header-cell *matHeaderCellDef mat-sort-header> Bentlévő kaució</th>
        <td class="deposit-col" mat-cell *matCellDef="let renter"> {{renter.deposit}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr (click)="updateRenter(renter)" mat-row *matRowDef="let renter; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Keresés eredménytelen az alábbi szűrővel "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  </div>

</div>

<app-add-renter [renter]="renterToUpdate" [update]="true" *ngIf="updateRenterPage"></app-add-renter>

