export class Rent {
  public id: null;
  public rent_start: string;
  public rent_end: string;
  public renter_id: string;
  public deposit: string;
  public car_plate: string;
  public kmh_state_before: string;
  public kmh_state_after: string;
  public takeaway_start: string;
  public takeaway_end: string;
  public finished: number;
  public deleted: number;
  public note: string|null|undefined;

  constructor() {
  }
}
