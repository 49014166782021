import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AppService} from '../app.service';

export interface RenterData {
  id: string;
  name: string;
  phone: string;
  email: string;
  mother_name: string;
  id_number: string;
  license_type: string;
  license_number: string;
  nationality: string;
  payment: string;
  deposit: string;
}

@Component({
  selector: 'app-renters',
  templateUrl: './renters.component.html',
  styleUrls: ['./renters.component.css']
})
export class RentersComponent implements OnInit {

  renterToUpdate: any;
  public updateRenterPage = false;

  displayedColumns: string[] = ['id', 'name', 'phone', 'email', 'mother_name', 'id_number', 'license_type', 'license_number',
    'nationality', 'payment', 'deposit'];
  dataSource: MatTableDataSource<RenterData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private service: AppService) {
  }

  ngOnInit(): void {
    this.getRentersList();
  }

  updateRenter(renter): void {
    this.updateRenterPage = true;
    this.renterToUpdate = renter;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getRentersList(): void {
    this.service.getListByModel('renter').subscribe(renters => {
      this.dataSource = new MatTableDataSource(renters);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

}
