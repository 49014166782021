import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tomorrow',
  templateUrl: './tomorrow.component.html',
  styleUrls: ['./tomorrow.component.css']
})
export class TomorrowComponent implements OnInit {

  events = [];

  constructor(
    public service: AppService,
    public router: Router) {
  }

  ngOnInit(): void {
    this.service.getTomorrow().subscribe(res => {
      this.events = res.data;
    });
  }

  navigateToRent(rentId): void {
    this.router.navigate(['berles-szerkesztes/' + rentId]);
  }

}
