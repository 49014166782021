import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../environments/environment';
import {catchError} from 'rxjs/operators';
import {PersistanceService} from './persistance.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  httpOptions: object;

  private loadToken(): void {
    this.httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.persistanceService.get('token')
      })
    };
  }

  constructor(
    private http: HttpClient,
    private persistanceService: PersistanceService
  ) {
    this.loadToken();
  }

  createModel(data, model): Observable<any> {
    console.log(data);
    this.loadToken();
    return this.http.post<any>(environment.apiUrl + '/' + model + '/create', data, this.httpOptions);
  }

  forceCreateModel(data, model): Observable<any> {
    this.loadToken();
    return this.http.post<any>(environment.apiUrl + '/' + model + '/create', data, this.httpOptions);
  }

  updateModel(data, model): Observable<any> {
    this.loadToken();
    return this.http.put<any>(environment.apiUrl + '/' + model + '/update', data, this.httpOptions);
  }

  deleteModel(data, model): Observable<any> {
    this.loadToken();
    return this.http.put<any>(environment.apiUrl + '/' + model + '/delete', data, this.httpOptions);
  }

  getListByModel(model): Observable<any> {
    this.loadToken();
    return this.http.get<any>(environment.apiUrl + '/' + model + '/list', this.httpOptions);
  }

  getListByRenterId(id): Observable<any> {
    this.loadToken();
    return this.http.get<any>(environment.apiUrl + '/renter/' + id + '/rents', this.httpOptions);
  }

  getRentsByPlate(plate): Observable<any> {
    this.loadToken();
    return this.http.get<any>(environment.apiUrl + '/rent/listByPlate/' + plate, this.httpOptions);
  }

  getRentsById(rentId): Observable<any> {
    this.loadToken();
    return this.http.get<any>(environment.apiUrl + '/rent/findById/' + rentId, this.httpOptions);
  }

  uploadImageFile(formData): Observable<any> {
    // 'Content-Type': 'multipart/form-data',

    const header = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.persistanceService.get('token')
    });

    return this.http.post<any>(environment.apiUrl + '/image/upload', formData, {headers: header});
  }

  getFreecars(): Observable<any> {
    this.loadToken();
    return this.http.get<any>(environment.apiUrl + '/car/list/freecars', this.httpOptions);
  }

  getRentableCars(): Observable<any> {
    this.loadToken();
    return this.http.get<any>(environment.apiUrl + '/car/list/rentable', this.httpOptions);
  }

  getRentableIntervalCars(from, to): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.persistanceService.get('token')
    });

    const interval = new HttpParams().set('from', from).set('to', to);

    return this.http.get<any>(environment.apiUrl + '/car/list/rentable', {headers: header, params: interval});
  }

  login(loginData): Observable<any> {
    this.loadToken();
    return this.http.post<any>(environment.apiUrl + '/user/login', loginData, this.httpOptions);
  }

  logout(token): Observable<any> {
    this.loadToken();
    return this.http.post<any>(environment.apiUrl + '/user/logout', this.httpOptions);
  }

  getWarnings(): Observable<any> {
    this.loadToken();
    return this.http.get<any>(environment.apiUrl + '/car/warnings', this.httpOptions);
  }

  getLogs(take, page): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.persistanceService.get('token')
    });

    const takeAndPage = new HttpParams().set('take', take).set('page', page);

    this.loadToken();
    return this.http.get<any>(environment.apiUrl + '/admin-logs', {headers: header, params: takeAndPage});
  }

  getSettings(): Observable<any> {
    this.loadToken();
    return this.http.get<any>(environment.apiUrl + '/settings', this.httpOptions);
  }

  updateKmSetting(data): Observable<any> {
    this.loadToken();
    return this.http.put<any>(environment.apiUrl + '/settings/1', data, this.httpOptions);
  }

  updateDocumentSetting(data): Observable<any> {
    this.loadToken();
    return this.http.put<any>(environment.apiUrl + '/settings/2', data, this.httpOptions);
  }

  changePassword(data): Observable<any> {
    this.loadToken();
    return this.http.put<any>(environment.apiUrl + '/user/change-password', data, this.httpOptions);
  }

  getRentsByDate(from, to): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.persistanceService.get('token')
    });

    const interval = new HttpParams().set('from', from).set('to', to);

    return this.http.get<any>(environment.apiUrl + '/rent/list/date', {headers: header, params: interval});
  }

  // tslint:disable-next-line:typedef
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  getTomorrow(): Observable<any>{
    return this.http.get<any>(environment.apiUrl + '/car/tomorrow-events', this.httpOptions);
  }

  getToday(): Observable<any>{
    return this.http.get<any>(environment.apiUrl + '/car/today-events', this.httpOptions);
  }
}
