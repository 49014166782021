<div class="new-model-component form-component">
  <h1>Új foglalás hozzáadása</h1>

    <mat-form-field *ngIf="!newRenter.name && !newRenter.phone" appearance="fill">
      <mat-label>Korábbi bérlő</mat-label>
      <mat-select [(ngModel)]="rent.renter_id">

        <mat-option>
          <ngx-mat-select-search [(ngModel)]="idNumberFilter" (ngModelChange)="filterIds(idNumberFilter)" type="text"
                                 noEntriesFoundLabel="nincs találat" placeholderLabel="keresés"></ngx-mat-select-search>
        </mat-option>
        <mat-option value=' '></mat-option>
        <mat-option *ngFor="let renter of filteredRenters" value="{{renter.id}}">
          {{renter.name + ' (' + renter.id_number + ')'}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field *ngIf="rent.renter_id === undefined || rent.renter_id === ' '" class="" appearance="fill">
      <mat-label>Új bérlő neve</mat-label>
      <input [(ngModel)]="newRenter.name" matInput pattern="^[-a-zA-Z_&`´#. áéíóúőŐÁÉÍÓÚÑñÇç]*$">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field *ngIf="rent.renter_id === undefined || rent.renter_id === ' '" class="" appearance="fill">
      <mat-label>Új bérlő telefonszáma</mat-label>
      <input [(ngModel)]="newRenter.phone" matInput pattern="^[0-9*/+-]*$">
    </mat-form-field>

    <div class="spacer"></div>

  <form class="form" [formGroup]="quickRentForm" (ngSubmit)="onSubmit()">

  <mat-form-field appearance="fill">
      <mat-label>Autó</mat-label>
      <mat-select [(ngModel)]="rent.car_plate" (ngModelChange)="setKmhBeforeState()" formControlName="carPlate">
        <mat-option [value]="rent.car_plate" disabled="">{{rent.car_plate}}</mat-option>
        <mat-option>
          <ngx-mat-select-search [(ngModel)]="carFilter" (ngModelChange)="filterCars(carFilter)" type="text"
                                 noEntriesFoundLabel="nincs találat"
                                 formControlName="filterCar" placeholderLabel="keresés"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let car of filteredCars" value="{{car.plate}}">
          {{car.brand + ' (' + car.plate + ')'}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Kaució</mat-label>
      <input [(ngModel)]="deposit" matInput formControlName="deposit">
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Foglalás kezdete</mat-label>
      <input [(ngModel)]="rent.rent_start" matInput [ngxMatDatetimePicker]="rentStart" formControlName="rentStart">
      <mat-datepicker-toggle matSuffix [for]="rentStart"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #rentStart [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                               [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'"
                               [enableMeridian]="false" [disableMinute]="true" [defaultTime]="[12, 0]" [hideTime]="false">
        <ng-template>
          <span>OK</span>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <button mat-raised-button (click)="setTimeNow('rent_start')">Foglalás most</button>

    <div class="spacer"></div>

    <mat-form-field class="" appearance="fill">
      <mat-label>Foglalás vége</mat-label>
      <input [(ngModel)]="rent.rent_end" matInput [ngxMatDatetimePicker]="rentEnd" formControlName="rentEnd">
      <mat-datepicker-toggle matSuffix [for]="rentEnd"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #rentEnd [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                               [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'"
                               [enableMeridian]="false" [disableMinute]="true" [defaultTime]="[12, 0]" [hideTime]="false">
        <ng-template>
          <span>OK</span>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <div class="spacer"></div>

    <button *ngIf="newRenter" mat-raised-button (click)="createQuickRent()">Foglalás indítása</button>
  </form>


</div>

