import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {Observable} from 'rxjs';

import Swal from 'sweetalert2';
import {Router} from '@angular/router';


@Component({
  selector: 'app-summarize',
  templateUrl: './summarize.component.html',
  styleUrls: ['./summarize.component.css']
})
export class SummarizeComponent implements OnInit {

  getRents = new Observable();
  getFreeCars = new Observable();
  rents: any;
  cars: any;

  rentsWithSmallFreeCars = [];
  rentsWithMidFreeCars = [];
  rentsWithOneSpaceFreeCars = [];
  rentsWithMicroBusFreeCars = [];
  rentsWithTruckFreeCars = [];

  allRents = {kis: [], közép: [], egyterű: [], mikrobusz: [], teherautó: [], option: 0, maxdates: {start: new Date(), end: new Date()}};

  currentDateForMonday: Date;
  currentDateForSunday: Date;
  today: Date;
  monday: Date;
  sunday: Date;
  carDateStart: Date;
  carDateEnd: Date;
  firstDateOfMonth: Date;
  lastDateOfMonth: Date;

  chosenMonth: any;

  dayInterval = false;
  weekInterval = true;
  monthInterval = false;

  options: any;

  data = [];
  ganttOptions: any;
  minDate: any;
  maxDate: any;

  small = true;
  mid = false;
  onespace = false;
  microbus = false;
  cargo = false;

  constructor(public service: AppService,
              public router: Router) {
  }

  ngOnInit(): void {
    this.today = new Date();
    this.currentDateForMonday = new Date();
    this.currentDateForSunday = new Date();

    this.monday = new Date(this.currentDateForMonday.setDate(this.currentDateForMonday.getDate() - this.currentDateForMonday.getDay() + 1));
    this.sunday = new Date(this.currentDateForSunday.setDate(this.currentDateForSunday.getDate() - this.currentDateForSunday.getDay() + 7));

    this.carDateStart = new Date(this.monday.getFullYear(), this.monday.getMonth(), this.monday.getDate(), 0, 1);
    this.carDateEnd = new Date(this.monday.getFullYear(), this.monday.getMonth(), this.monday.getDate(), 0, 2);

    this.getDataByWeek(this.carDateStart, this.carDateEnd, 'kis');
    this.getDataByWeek(this.carDateStart, this.carDateEnd, 'közép');
    this.getDataByWeek(this.carDateStart, this.carDateEnd, 'egyterű');
    this.getDataByWeek(this.carDateStart, this.carDateEnd, 'mikrobusz');
    this.getDataByWeek(this.carDateStart, this.carDateEnd, 'teherautó');
  }

  datetimeConverter(date, mode): string {
    date = new Date(date);

    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (mode === 1) {
      return year + '-' + month + '-' + day + '&nbsp;' + hours + ':' + minutes;
    } else if (mode === 2) {
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
    }
  }

  dateConverter(date): string {
    date = new Date(date);

    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return year + '-' + month + '-' + day;
  }

  changeIterators(change): void {
    if (change === 'dec') {
      // tslint:disable-next-line:max-line-length
      this.monday = new Date(this.currentDateForMonday.setDate(this.currentDateForMonday.getDate() + 1 - this.currentDateForMonday.getDay() - 7));
      // tslint:disable-next-line:max-line-length
      this.sunday = new Date(this.currentDateForSunday.setDate(this.currentDateForSunday.getDate() - this.currentDateForSunday.getDay() - 7));
      this.carDateStart = new Date(this.monday.getFullYear(), this.monday.getMonth(), this.monday.getDate(), 0, 1);
      this.carDateEnd = new Date(this.monday.getFullYear(), this.monday.getMonth(), this.monday.getDate(), 0, 2);
    } else if (change === 'inc') {
      // tslint:disable-next-line:max-line-length
      this.monday = new Date(this.currentDateForMonday.setDate(this.currentDateForMonday.getDate() + 1 - this.currentDateForMonday.getDay() + 7));
      // tslint:disable-next-line:max-line-length
      this.sunday = new Date(this.currentDateForSunday.setDate(this.currentDateForSunday.getDate() - this.currentDateForSunday.getDay() + 7));
      this.carDateStart = new Date(this.monday.getFullYear(), this.monday.getMonth(), this.monday.getDate(), 0, 1);
      this.carDateEnd = new Date(this.monday.getFullYear(), this.monday.getMonth(), this.monday.getDate(), 0, 2);
    }
    this.getDataByWeek(this.carDateStart, this.carDateEnd, 'kis');
    this.getDataByWeek(this.carDateStart, this.carDateEnd, 'közép');
    this.getDataByWeek(this.carDateStart, this.carDateEnd, 'egyterű');
    this.getDataByWeek(this.carDateStart, this.carDateEnd, 'mikrobusz');
    this.getDataByWeek(this.carDateStart, this.carDateEnd, 'teherautó');
  }

  getDataByMonth(size): void {
    this.rentsWithSmallFreeCars = [];
    this.rentsWithMidFreeCars = [];
    this.rentsWithOneSpaceFreeCars = [];
    this.rentsWithMicroBusFreeCars = [];
    this.rentsWithTruckFreeCars = [];

    // @ts-ignore
    const firstDay = new Date(this.firstDateOfMonth.year(), this.firstDateOfMonth.month(), this.firstDateOfMonth.date());
    // @ts-ignore
    const lastDay = new Date(this.lastDateOfMonth.year(), this.lastDateOfMonth.month(), this.lastDateOfMonth.date());
    // @ts-ignore
    const carDateStart = new Date(this.firstDateOfMonth.year(), this.firstDateOfMonth.month(), this.firstDateOfMonth.date(), 0, 1);
    // @ts-ignore
    const carDateEnd = new Date(this.firstDateOfMonth.year(), this.firstDateOfMonth.month(), this.firstDateOfMonth.date(), 0, 2);

    this.getRents = this.service.getRentsByDate(this.dateConverter(firstDay), this.dateConverter(lastDay));
    // @ts-ignore
    this.allRents.option = {
      width: 1800,
      height: 500,
      hAxis: {format: 'MM. dd.'}
    };
    // @ts-ignore
    this.allRents.maxdates.start = firstDay.setHours(0, 0);
    // @ts-ignore
    this.allRents.maxdates.end = lastDay.setHours(23, 59);
    this.getSummedRents(carDateStart, carDateEnd, size);
  }

  getDataByWeek(carStart, carEnd, size): void {
    this.rentsWithSmallFreeCars = [];
    this.rentsWithMidFreeCars = [];
    this.rentsWithOneSpaceFreeCars = [];
    this.rentsWithMicroBusFreeCars = [];
    this.rentsWithTruckFreeCars = [];

    this.getRents = this.service.getRentsByDate(this.dateConverter(this.monday), this.dateConverter(this.sunday));
    // @ts-ignore
    this.allRents.option = {
      width: 1550,
      height: 500,
      hAxis: {format: 'EEE (MM. dd.)'}
    };
    // @ts-ignore
    this.allRents.maxdates.start = this.monday.setHours(0, 0);
    // @ts-ignore
    this.allRents.maxdates.end = this.sunday.setHours(23, 59);
    this.getSummedRents(carStart, carEnd, size);
  }

  getDataByDay(selectedDay, size): void {
    this.rentsWithSmallFreeCars = [];
    this.rentsWithMidFreeCars = [];
    this.rentsWithOneSpaceFreeCars = [];
    this.rentsWithMicroBusFreeCars = [];
    this.rentsWithTruckFreeCars = [];

    let startOfTheDay;
    let endOfTheDay;
    if (selectedDay && selectedDay.year) {
      startOfTheDay = new Date(selectedDay.year(), selectedDay.month(), selectedDay.date(), 0, 0);
      endOfTheDay = new Date(selectedDay.year(), selectedDay.month(), selectedDay.date(), 23, 59);
      this.carDateStart = new Date(selectedDay.year(), selectedDay.month(), selectedDay.date(), 0, 1);
      this.carDateEnd = new Date(selectedDay.year(), selectedDay.month(), selectedDay.date(), 0, 2);
    } else {
      startOfTheDay = new Date(selectedDay.getFullYear(), selectedDay.getMonth() + 1, selectedDay.getDate());
      endOfTheDay = new Date(selectedDay.getFullYear(), selectedDay.getMonth() + 1, selectedDay.getDate());

      this.carDateStart = new Date(selectedDay.getFullYear(), selectedDay.getMonth() + 1, selectedDay.getDate(), 0, 1);
      this.carDateEnd = new Date(selectedDay.getFullYear(), selectedDay.getMonth() + 1, selectedDay.getDate(), 0, 2);
    }
    this.getRents = this.service.getRentsByDate(this.dateConverter(startOfTheDay), this.dateConverter(endOfTheDay));
    // @ts-ignore
    this.allRents.option = {
      width: 1800,
      height: 500,
      hAxis: {format: ['HH:mm', 'ha']}
    };
    this.allRents.maxdates.start = startOfTheDay.setHours(0, 0);
    this.allRents.maxdates.end = endOfTheDay.setHours(23, 59);
    this.getSummedRents(this.carDateStart, this.carDateEnd, size);
  }

  getSummedRents(carStart, carEnd, size): void {
    this.getRents.subscribe(rents => {
      this.rents = rents;
      this.rents.forEach(rent => {
        const tooltip = ('<p class="no-break-line">'
          + 'id: ' + rent.id + '<br>' +
          rent.renter.name + ' (' + rent.renter.id_number + ')' + '<br>' +
          'kezdés: ' + this.datetimeConverter(new Date(rent.rent_start), 1) + '<br>' +
          'vége: ' + this.datetimeConverter(new Date(rent.rent_end), 1) + '<br>' +
          'rendszám: ' + rent.car_plate + '<br>' +
          'auto: ' + rent.car.brand + '</p>');

        if (size === 'kis' && rent.car.category_size.includes(size)) {
          // tslint:disable-next-line:max-line-length
          return this.rentsWithSmallFreeCars.push([rent.car_plate + ' ' + rent.car.brand, rent.car.brand + ' (' + rent.car_plate + ')', tooltip, new Date(rent.week_start_date), new Date(rent.week_end_date)]);
        } else if (size === 'közép' && rent.car.category_size.includes(size)) {
          // tslint:disable-next-line:max-line-length
          return this.rentsWithMidFreeCars.push([rent.car_plate + ' ' + rent.car.brand, rent.car.brand + ' (' + rent.car_plate + ')', tooltip, new Date(rent.week_start_date), new Date(rent.week_end_date)]);
        } else if (size === 'egyterű' && rent.car.category_size.includes(size)) {
          // tslint:disable-next-line:max-line-length
          return this.rentsWithOneSpaceFreeCars.push([rent.car_plate + ' ' + rent.car.brand, rent.car.brand + ' (' + rent.car_plate + ')', tooltip, new Date(rent.week_start_date), new Date(rent.week_end_date)]);
        } else if (size === 'mikrobusz' && rent.car.category_size.includes(size)) {
          // tslint:disable-next-line:max-line-length
          return this.rentsWithMicroBusFreeCars.push([rent.car_plate + ' ' + rent.car.brand, rent.car.brand + ' (' + rent.car_plate + ')', tooltip, new Date(rent.week_start_date), new Date(rent.week_end_date)]);
        } else if (size === 'teherautó' && rent.car.category_size.includes(size)) {
          // tslint:disable-next-line:max-line-length
          return this.rentsWithTruckFreeCars.push([rent.car_plate + ' ' + rent.car.brand, rent.car.brand + ' (' + rent.car_plate + ')', tooltip, new Date(rent.week_start_date), new Date(rent.week_end_date)]);
        }

      });

      // ToDo: free cars by size
      this.getFreeCars = this.service.getRentableCars();
      this.getFreeCars.subscribe(cars => {
        this.allRents.kis = this.rentsWithSmallFreeCars;
        this.allRents.közép = this.rentsWithMidFreeCars;
        this.allRents.egyterű = this.rentsWithOneSpaceFreeCars;
        this.allRents.mikrobusz = this.rentsWithMicroBusFreeCars;
        this.allRents.teherautó = this.rentsWithTruckFreeCars;
        const carsWithRents = [];
        const smallCarsWithRents = [];
        this.rentsWithSmallFreeCars.forEach(rent => {
          carsWithRents.push(rent[0]);
        });

        this.rentsWithMidFreeCars.forEach(rent => {
          carsWithRents.push(rent[0]);
        });

        this.rentsWithOneSpaceFreeCars.forEach(rent => {
          carsWithRents.push(rent[0]);
        });

        this.rentsWithMicroBusFreeCars.forEach(rent => {
          carsWithRents.push(rent[0]);
        });

        this.rentsWithTruckFreeCars.forEach(rent => {
          carsWithRents.push(rent[0]);
        });
        this.cars = cars;
        this.cars.forEach(car => {
          if (carsWithRents.indexOf(car.plate + ' ' + car.brand) === -1) {
            car.category_size.split(',').forEach(category => {
              if (category in this.allRents) {
                this.allRents[category].push([car.plate + ' ' + car.brand, ' ', ' ', carStart, carEnd]);
              }
            });
          }
        });
        this.makeChart(this.allRents, size);
      });
    });
  }

  makeChart(dataFromSummarize, size): void {
    google.charts.load('current', {packages: ['timeline'], language: 'hu'}).then(r => {
      const rents = [];
      this.data = dataFromSummarize[size];
      this.ganttOptions = dataFromSummarize.option;
      this.minDate = dataFromSummarize.maxdates.start;
      this.maxDate = dataFromSummarize.maxdates.end;

      const isRentEmpty = (currentValue) => currentValue === true;
      this.data.forEach(rent => {
        rents.push(rent[2] === ' ');
      });

      if (rents.every(isRentEmpty)) {
        // Swal.fire('Erre az időszakra nincs "' + size + '" méretű foglalás', '', 'warning');
      } else {

        // @ts-ignore
        google.charts.setOnLoadCallback(this.drawChart(this.data, this, size));
      }
    });
  }

  drawChart(data, parent, size): void {
    const container = document.getElementById('timeline-' + size);
    const containerClone = document.getElementById('timeline-clone-' + size);
    const chart = new google.visualization.Timeline(container);
    const chartClone = new google.visualization.Timeline(containerClone);
    const dataTable = new google.visualization.DataTable();
    const dataTableClone = new google.visualization.DataTable();

    dataTable.addColumn({type: 'string', id: 'Plate'});
    dataTable.addColumn({type: 'string', id: 'Dummy'});
    dataTable.addColumn({type: 'string', role: 'tooltip'});
    dataTable.addColumn({type: 'date', id: 'Start'});
    dataTable.addColumn({type: 'date', id: 'End'});
    dataTable.addRows(data);
    // tslint:disable-next-line:max-line-length
    dataTable.addRow((['táblázat vége', ' ', '', new Date(new Date(this.minDate).getFullYear(), new Date(this.minDate).getMonth(), new Date(this.minDate).getDate(), 0, 0, 0),
      new Date(new Date(this.maxDate).getFullYear(), new Date(this.maxDate).getMonth(), new Date(this.maxDate).getDate(), 23, 59, 0)]));

    dataTableClone.addColumn({type: 'string', id: 'Plate'});
    dataTableClone.addColumn({type: 'string', id: 'Dummy'});
    dataTableClone.addColumn({type: 'string', role: 'tooltip'});
    dataTableClone.addColumn({type: 'date', id: 'Start'});
    dataTableClone.addColumn({type: 'date', id: 'End'});
    dataTableClone.addRows([
      // tslint:disable-next-line:max-line-length
      ['medzsik', '', 'magic', new Date(new Date(this.minDate).getFullYear(), new Date(this.minDate).getMonth(), new Date(this.minDate).getDate(), 0, 0, 0),
        new Date(new Date(this.maxDate).getFullYear(), new Date(this.maxDate).getMonth(), new Date(this.maxDate).getDate(), 23, 59, 0)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)],
      ['FRD-327 Ford Focus (tuning)', ' ', 'tooltip', new Date(this.minDate), new Date(this.maxDate)]]);

    // tslint:disable-next-line:only-arrow-functions typedef
    google.visualization.events.addListener(chart, 'select', function() {
      const selection = chart.getSelection();
      if (selection[0].column === null) {
        selection[0].column = 0;
      }
      if (selection.length) {
        parent.getRentById(dataTable.getValue(selection[0].row, 2).split('id: ')[1].split('<br>')[0]);
      }
    });

    chart.draw(dataTable, this.ganttOptions);
    chartClone.draw(dataTableClone, this.ganttOptions);

    document.querySelectorAll('rect[width=\'3\']').forEach(rect => {
      // @ts-ignore
      rect.style.display = 'none';
    });

    document.querySelectorAll('rect text').forEach(rect => {
      // @ts-ignore
      rect.style.display = 'none';
    });

    document.querySelectorAll('path[stroke-width=\'1\']').forEach(path => {
      // @ts-ignore
      path.style.stroke = 'rgb(77, 77, 77)';
    });

    document.querySelectorAll('text[text-anchor=\'middle\']').forEach(text => {
      // @ts-ignore
      text.style.textAnchor = 'start';
    });
  }

  getRentById(rentId): void {
    this.router.navigate(['berles-szerkesztes/' + rentId]);
  }
}
