import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-warnings',
  templateUrl: './warnings.component.html',
  styleUrls: ['./warnings.component.css']
})
export class WarningsComponent implements OnInit {

  constructor(
    public service: AppService,
    private router: Router
    ) {
  }

  warnings = [];

  ngOnInit(): void {
    this.service.getWarnings().subscribe(req => {
      for (const [key, value] of Object.entries(req.data)) {
        this.warnings.push(value);
      }
    });
  }

  checkCarDocument(warningElement): string {
    if (warningElement) {
      return 'Lejárt a forgalmi!';
    }
  }

  checkVignette(warningElement): string {
    if (warningElement) {
      return 'Lejárt az autópálya matrica!';
    }
  }

  checkService(warningElement): string {
    if (warningElement) {
      return 'Közel a szervíz intervallum vége!';
    }
  }

  checkCarDocumentExpiration(warningElement): string {
    if (warningElement) {
      return 'Hamarosan lejár a forgalmi!';
    }
  }

  checkCarReturned(warningElement): string {
    if (warningElement) {
      return 'Lejárt a bérlés, de az autó nincs a telephelyen!';
    }
  }

  navToCarUpdate(car: string): void {
    void this.router.navigate(['/autok/' + car.split('(')[1].replace(')', '')]);
  }
}
