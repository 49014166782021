import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import Swal from 'sweetalert2';
// @ts-ignore
import moment = require('moment/moment');
import {Observable} from 'rxjs';

export interface RentData {
  id: string;
  rent_start: string;
  rent_end: string;
  renter_id: string;
  deposit: string;
  car_plate: string;
  kmh_state_before: string;
  kmh_state_after: string;
  takeaway_start: string;
  takeaway_end: string;
  finished: string;
}

@Component({
  selector: 'app-rents',
  templateUrl: './rents.component.html',
  styleUrls: ['./rents.component.css']
})

export class RentsComponent implements OnInit {

  rentToUpdate: any;
  updateRentPage = false;
  showFinishedRents;
  showAllRents = true;
  rents: unknown = [];
  finishedRents = [];
  unfinishedRents = [];
  getRents = new Observable();

  constructor(private service: AppService) {
  }

  ngOnInit(): void {
    this.getRents = this.service.getListByModel('rent');
    this.getLists();
  }

  updateRent(rent): void {
    if (rent.finished === 1) {
      Swal.fire({
        title: 'Ez a bérlés már lezárt állapotú. Biztos, hogy szerkeszteni akarod?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Igen',
        cancelButtonText: 'Nem'
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateRentPage = true;
          this.rentToUpdate = rent;
          if (rent.kmh_state_before !== rent.kmh_state_after) {
            this.rentToUpdate.kmh_state_after = rent.kmh_state_before;
          }
          this.rentToUpdate.rent_start = moment(this.rentToUpdate.rent_start.replace(' ', 'T'));
          this.rentToUpdate.rent_end = moment(this.rentToUpdate.rent_end.replace(' ', 'T'));
          this.rentToUpdate.takeaway_start = moment(this.rentToUpdate.takeaway_start.replace(' ', 'T'));
          this.rentToUpdate.takeaway_end = moment(this.rentToUpdate.takeaway_end.replace(' ', 'T'));
        }
      });
    } else {
      this.updateRentPage = true;
      this.rentToUpdate = rent;
      if (rent.kmh_state_before !== rent.kmh_state_after) {
        this.rentToUpdate.kmh_state_after = rent.kmh_state_before;
      }
      this.rentToUpdate.rent_start = moment(this.rentToUpdate.rent_start.replace(' ', 'T'));
      this.rentToUpdate.rent_end = moment(this.rentToUpdate.rent_end.replace(' ', 'T'));
      this.rentToUpdate.takeaway_start = moment(this.rentToUpdate.takeaway_start.replace(' ', 'T'));
      this.rentToUpdate.takeaway_end = moment(this.rentToUpdate.takeaway_end.replace(' ', 'T'));
    }
  }

  getLists(): void {
    this.getRents.subscribe(rents => {
      this.rents = rents;
      // @ts-ignore
      this.rents.forEach(rent => {
        if (rent.finished === 1) {
          this.finishedRents.push(rent);
        } else if (rent.finished === 0) {
          this.unfinishedRents.push(rent);
        }
      });
    });
  }

}
