<div class="container">
  <div class="col-12 login-card">
    <img class="logo" src="assets/logo.png">
    <form class="login-form" [formGroup]="userForm" (ngSubmit)="onSubmit()">

      <div class="login-input">
        <mat-form-field class="input-form" appearance="">
          <mat-label>Felhasználó</mat-label>
          <input [(ngModel)]="username" matInput formControlName="username">
        </mat-form-field>
      </div>

      <div class="spacer"></div>

      <div class="login-input">
        <mat-form-field class="input-form" appearance="">
          <mat-label>Jelszó</mat-label>
          <input [(ngModel)]="password" matInput type="password" formControlName="password">
        </mat-form-field>
      </div>

      <div class="spacer"></div>

      <button class="login-button" mat-raised-button (click)="login()">Bejelentkezés</button>
    </form>

  </div>
</div>


