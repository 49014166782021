export class Renter {
  public id: null;
  public name: string;
  public phone: string;
  public email: string;
  public mother_name: string;
  public id_number: string;
  public address: string;
  public license_type: ['EU', 'HU', 'OTH'];
  public license_number: string;
  public nationality: string;
  public payment: ['KP', 'BANK'];
  public deposit: number;
  public finished: string;
  public deleted: number;
  public force_create: number;

  constructor() {
  }
}
