import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {environment} from '../../environments/environment';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {Rent} from '../models/rent.model';

@Component({
  selector: 'app-cars',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.css']
})
export class CarsComponent implements OnInit {

  cars = [];
  filteredCars = [];
  carToUpdate: any;
  carPlate: any;
  kmhState: any;
  updateCarPage = false;
  rentCarPage = false;
  public rentByCarPage = false;
  imgPath = environment.imgPath;
  carCounter = 0;

  s = false;
  m = false;
  l = false;
  xl = false;
  xxl = false;

  private carPlateToUpdate: any;

  constructor(
    private service: AppService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params && params.id) {
        this.carPlateToUpdate = params.id;
      }
      this.getCarList();
    });
  }

  updateCar(car): void {
    Swal.fire({
      title: 'Biztosan módosítani akarod ezt az autót?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen',
      cancelButtonText: 'Nem'
    }).then((result) => {
      if (result.isConfirmed) {
        window.scrollTo(0, 0);
        this.updateCarPage = true;
        this.carToUpdate = car;
        this.carToUpdate.category_size = this.carToUpdate.category_size.split(',');
        if (this.carToUpdate.service_date) {
          this.carToUpdate.service_date = new Date(this.carToUpdate.service_date.replace(' ', 'T'));
        }
      }
    });
  }

  deleteCar(car): void {
    Swal.fire({
      title: 'Biztosan törölni akarod ezt az autót?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen',
      cancelButtonText: 'Nem'
    }).then((result) => {
      if (result.isConfirmed) {
        car.deleted = true;
        this.service.deleteModel(car, 'car').subscribe(res => {
          this.getCarList();
          Swal.fire('Sikeresen törölted ezt az autót!', '', 'success');
        }, error => {
          let msg = '';
          for (const [key, rent] of Object.entries<Rent>(error.error.data.rents)) {
            msg = msg.concat(rent.rent_start + '\n - \n ' + rent.rent_end + '\n\n' + '<a href="/berles-szerkesztes/' + rent.id + '">Bérléshez</a>' + '\n\n');
          }
          Swal.fire(error.error.data.message, msg, 'warning');
        });
      }
    });
  }

  rentCar(car): void {
    Swal.fire({
      title: 'Biztosan bérlést akarsz indítani erre az autóra?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen',
      cancelButtonText: 'Nem'
    }).then((result) => {
      if (result.isConfirmed) {
        window.scrollTo(0, 0);
        this.rentCarPage = true;
        this.carPlate = car.plate;
        this.kmhState = car.kmh_state;
      }
    });
  }

  showRents(plate): void {
    Swal.fire({
      title: 'Biztosan megakarod nézni ennek az autónak a bérléseit?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen',
      cancelButtonText: 'Nem'
    }).then((result) => {
      if (result.isConfirmed) {
        window.scrollTo(0, 0);
        this.rentByCarPage = true;
        this.carPlate = plate;
      }
    });
  }

  getCarList(): void {
    this.service.getListByModel('car').subscribe(cars => {
      this.cars = cars;
      this.carCounter = cars.length;
      this.filteredCars = cars;

      if (this.carPlateToUpdate) {
        window.scrollTo(0, 0);
        this.updateCarPage = true;
        this.carToUpdate = this.cars.filter(car => car.plate === this.carPlateToUpdate)[0];
        this.carToUpdate.category_size = this.carToUpdate.category_size.split(',');
        if (this.carToUpdate.service_date) {
          this.carToUpdate.service_date = new Date(this.carToUpdate.service_date.replace(' ', 'T'));
        }
      }
    });
  }

  checkDate(date): string {
    if (typeof date === 'string') {
      if (new Date() > new Date(date.replace(' ', 'T'))) {
        return 'Lejárt: ' + date;
      } else {
        return date;
      }
    } else {
      return 'Nincs megadva a forgalmi lejárta';
    }
  }

  checkServiceDate(date): string {
    if (typeof date === 'string') {
      if (new Date() > new Date(date.replace(' ', 'T'))) {
        return 'Legutóbbi szervíz: ' + date;
      } else {
        return date;
      }
    } else {
      return 'Nincs megadva szerzív időpont';
    }
  }

  checkVignette(date): string {
    if (typeof date === 'string') {
      if (date === '1970-01-01') {
        return 'Nem rendelkezik matricával';
      } else if (new Date() > new Date(date.replace(' ', 'T'))) {
        return 'Lejárt: ' + date;
      } else {
        return date;
      }
    } else {
      return 'Nem rendelkezik matricával';
    }
  }

  applyFilter(event: Event): void {
    this.filteredCars = [];
    const filterValue = (event.target as HTMLInputElement).value;
    this.cars.forEach(car => {
      const carPlateWithBrand = car.plate + car.brand;
      if (carPlateWithBrand.toLowerCase().includes(filterValue.toLowerCase())) {
        this.filteredCars.push(car);
      }
    });
  }

  filterCarsBySize(size): void {
    this.filteredCars = this.cars.filter(car => car.category_size.includes(size));
    this.carCounter = this.filteredCars.length;
  }

  setButtonColor(size): string {
    if (this[size] === true) {
      return 'basic';
    } else {
      return 'primary';
    }
  }

  undo(): void {
    this.updateCarPage = false;
    this.getCarList();
  }
}
