import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {CarsComponent} from './cars/cars.component';
import {HomeComponent} from './home/home.component';
import {RentersComponent} from './renters/renters.component';
import {RentsComponent} from './rents/rents.component';
import {AuthGuardService} from './auth-guard.service';
import {LoginComponent} from './login/login.component';
import {AddCarComponent} from './add-car/add-car.component';
import {AddRenterComponent} from './add-renter/add-renter.component';
import {AddRentComponent} from './add-rent/add-rent.component';
import {CarsFreeComponent} from './cars-free/cars-free.component';
import {WarningsComponent} from './warnings/warnings.component';
import {SettingsComponent} from './settings/settings.component';
import {IntervalSearchComponent} from './interval-search/interval-search.component';
import {LogComponent} from './log/log.component';
import {QuickRentComponent} from './quick-rent/quick-rent.component';
import {UpdateRentComponent} from './update-rent/update-rent.component';
import {TomorrowComponent} from './tomorrow/tomorrow.component';
import {SummarizeComponent2} from './summarize-2/summarize2.component';
import {TodayComponent} from './today/today.component';

const routes: Routes = [
  {path: '', component: HomeComponent, children: [
      {path: 'foglalasok', component: SummarizeComponent2, canActivate: [AuthGuardService]},
      {path: 'holnapi-esemenyek', component: TomorrowComponent, canActivate: [AuthGuardService]},
      {path: 'mai-esemenyek', component: TodayComponent, canActivate: [AuthGuardService]},
      {path: 'autok', component: CarsComponent, canActivate: [AuthGuardService]},
      {path: 'autok/:id', component: CarsComponent, canActivate: [AuthGuardService]},
      {path: 'szabad-autok', component: CarsFreeComponent, canActivate: [AuthGuardService]},
      {path: 'uj-auto', component: AddCarComponent, canActivate: [AuthGuardService]},
      {path: 'berlesek', component: RentsComponent, canActivate: [AuthGuardService]},
      {path: 'berlesek/:rents', component: RentsComponent, canActivate: [AuthGuardService]},
      {path: 'uj-berles', component: AddRentComponent, canActivate: [AuthGuardService]},
      {path: 'berles-szerkesztes/:rentId', component: UpdateRentComponent, canActivate: [AuthGuardService]},
      {path: 'berlok', component: RentersComponent, canActivate: [AuthGuardService]},
      {path: 'uj-berlo', component: AddRenterComponent, canActivate: [AuthGuardService]},
      {path: 'figyelmeztetesek', component: WarningsComponent, canActivate: [AuthGuardService]},
      {path: 'beallitasok', component: SettingsComponent, canActivate: [AuthGuardService]},
      {path: 'idoszakos-kereses', component: IntervalSearchComponent, canActivate: [AuthGuardService]},
      {path: 'foglalas', component: QuickRentComponent, canActivate: [AuthGuardService]},
      {path: 'log', component: LogComponent, canActivate: [AuthGuardService]},
    ],  canActivate: [AuthGuardService]},
  {path: 'login', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
