import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  carServiceKm: number;
  carServiceKmTitle: string;
  carDocumentExpiration: number;
  carDocumentExpirationTitle: string;
  oldPassword: any;
  newPassword: any;
  newPasswordConfirm: any;

  constructor(public service: AppService) {
  }

  ngOnInit(): void {
    this.service.getSettings().subscribe(res => {
      console.log(res);
      this.carServiceKm = res.data[0].value;
      this.carServiceKmTitle = res.data[0].name;

      this.carDocumentExpiration = res.data[1].value;
      this.carDocumentExpirationTitle = res.data[1].name;
    });
  }


  updateSettings(): void {
    if (!this.checkPasswords()) {
      Swal.fire('Az új jelszó megerősítése helytelen!', '', 'warning');
    } else {
      this.service.updateKmSetting({
        id: 1,
        name: this.carServiceKmTitle,
        value: Number(this.carServiceKm)
      }).subscribe(kmRes => {
        this.service.updateDocumentSetting({
          id: 2,
          name: this.carDocumentExpirationTitle,
          value: Number(this.carDocumentExpiration)
        }).subscribe(docRes => {
          if ( this.oldPassword && this.newPassword && this.newPasswordConfirm){
            this.service.changePassword({
              current_password: this.oldPassword,
              new_password: this.newPassword,
              new_password_confirmation: this.newPasswordConfirm
            }).subscribe( passRes => {
              this.oldPassword = '';
              this.newPassword = '';
              this.newPasswordConfirm = '';
              Swal.fire('Sikeresen mentetted a beállításokat!', '', 'success');
            }, error => {
              Swal.fire(error.error.data, '', 'warning');

              console.log(error);
            });
          } else {
            Swal.fire('Sikeresen mentetted a beállításokat!', '', 'success');
          }
        });
      });
    }
  }

  checkPasswords(): boolean {
    return this.newPassword === this.newPasswordConfirm;
  }

}
