import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PersistanceService} from './persistance.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private http: HttpClient,
    private router: Router,
    private persistanceService: PersistanceService
  ) {
  }

  // @ts-ignore
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {

    const token = this.persistanceService.get('token');

    if (token === null) {
      this.router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  }

  getRandomInt(max): number {
    return Math.floor(Math.random() * max);
  }

}
