<div class="new-model-component">
  <h1>Mai események</h1>

  <ng-container *ngIf="events.length === 0">
    <h3>Nincsenek események mára!</h3>
  </ng-container>

  <div class="container">
    <div class="row">
      <div class="warning-wrapper col-12 col-md-6" *ngFor="let event of events">
        <div class="warning" (click)="navigateToRent(event.rent_id)">
          <h4>{{event.event_name}}
            <ng-container *ngIf="event.event_name === 'Aut\u00f3 visszahoz\u00e1s'">
              <img class="icon" src="assets/icons/return.svg" alt="">
            </ng-container>
            <ng-container *ngIf="event.event_name === 'Aut\u00f3 kiad\u00e1s'">
              <img class="icon" src="assets/icons/take.svg" alt="">
            </ng-container>
          </h4>
          <br>

          <p *ngIf="event.description !== null">Megjegyzés: {{ event.description }}</p>
          <p> Autó: {{event.car}}</p>
          <p> Bérlő: {{event.person_name ? event.person_name : '-'}}  - {{event.phone ? event.phone : ''}}</p>
          <!--<p> Bérlő telefonszám: {{event.phone ? event.phone : '-'}}</p>-->
          <p> Időpont: {{event.datetime}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
