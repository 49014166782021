import {Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../environments/environment';
import {AppService} from '../app.service';
import Swal from 'sweetalert2';
import {MatTableDataSource} from '@angular/material/table';
import {RenterData} from '../renters/renters.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

export interface CarData {
  plate: string;
  category_size: string;
  active: string;
  fuel: string;
  brand: string;
  vignette_expiration: string;
  service_date: string;
  service_interval: string;
  car_document_expiration: string;
  kmh_state: string;
}

@Component({
  selector: 'app-interval-search',
  templateUrl: './interval-search.component.html',
  styleUrls: ['./interval-search.component.css']
})
export class IntervalSearchComponent implements OnInit {

  displayedColumns: string[] = ['plate', 'brand', 'category_size', 'fuel', 'kmh_state', 'service_interval', 'vignette_expiration',
    'service_date', 'car_document_expiration'];
  dataSource: MatTableDataSource<CarData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  cars = [];
  filteredCars = [];
  carToUpdate: any;
  carPlate: any;
  kmhState: any;
  updateCarPage = false;
  rentCarPage = false;
  imgPath = environment.imgPath;
  filterValue = '';

  intervalStartDate: any;
  intervalEndDate: any;

  constructor(private service: AppService) {
  }

  ngOnInit(): void {
    this.getCarList();
  }

  getCarList(): void {
    this.service.getRentableCars().subscribe(cars => {
      this.cars = cars;
      console.log(cars);
      this.filteredCars = cars;
      this.dataSource = new MatTableDataSource(this.filteredCars);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(): void {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  rentCar(car): void {
    Swal.fire({
      title: 'Biztosan bérlést akarsz indítani erre az autóra?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Igen',
      cancelButtonText: 'Nem'
    }).then((result) => {
      if (result.isConfirmed) {
        window.scrollTo(0, 0);
        this.rentCarPage = true;
        this.carPlate = car.plate;
        this.kmhState = car.kmh_state;
      }
    });
  }

  searchInterval(): void {
    if (this.intervalStartDate > this.intervalEndDate) {
      Swal.fire('A keresés vége korábban nem lehet korábban a kezdeténél', '', 'warning');
    } else if (this.intervalStartDate && this.intervalEndDate) {
      // tslint:disable-next-line:max-line-length
      this.service.getRentableIntervalCars(this.datetimeConverter(this.intervalStartDate), this.datetimeConverter(this.intervalEndDate)).subscribe(intervalCars => {
        this.filteredCars = intervalCars;
        this.dataSource = new MatTableDataSource(this.filteredCars);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    } else if (this.intervalStartDate === null && this.intervalEndDate === null) {
      this.getCarList();
    }
  }

  datetimeConverter(date): string {
    if (!date) {
      return;
    }

    const year = date.year();
    let month = date.month() + 1;
    let day = date.date();

    let hours = date.hour();
    let minutes = date.minute();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    if (hours < 10) {
      hours = '0' + hours;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00';
    // return year + '-' + month + '-' + day + '+' + hours + '%3A' + minutes + '%3A00';
  }

  showActive(active): string {
    if (active === 1) {
      return 'Aktív';
    } else if (active === 0) {
      return 'Nem Aktív';
    }
  }

  showServiceDate(date): string {
    if (date) {
      return date;
    } else {
      return 'Nincs dátum';
    }
  }

  showVignette(vignette): string {
    if (vignette) {
      return vignette;
    } else {
      return 'Nincs matrica';
    }
  }
}
