<div class="new-model-component">
  <h1>Logolás ({{logs}})</h1>

  <mat-form-field appearance="fill">
    <mat-label>Logok száma oldalanként</mat-label>
    <input matInput [(ngModel)]="pageSize" type="number">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Oldal {{maxPage}}</mat-label>
    <input matInput [(ngModel)]="page" disabled type="number">
  </mat-form-field>

  <button mat-stroked-button (click)="navigateLeft()"><i class="material-icons">arrow_back_ios</i></button>
  <button mat-stroked-button (click)="navigateRight()"><i class="material-icons">arrow_forward_ios</i></button>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef> Felhasználó</th>
      <td mat-cell *matCellDef="let element"> {{element.user}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Művelet neve</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="data">
      <th mat-header-cell *matHeaderCellDef> Művelet</th>
      <td mat-cell [innerHTML]="element.data" *matCellDef="let element"> {{element.data}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div>

