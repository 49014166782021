import {Component, OnInit} from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'rent21-frontend';

  ngOnInit(): void {
    $('[data-widget="treeview"]').Treeview('init');
  }

}


