<div class="new-model-component">
  <h1>Összes bérlés</h1>

  <mat-icon *ngIf="updateRentPage" class="update-car" (click)="updateRentPage = false">undo</mat-icon>

  <mat-form-field *ngIf="!updateRentPage" appearance="standard">
    <mat-label>Keresés</mat-label>
    <input [(ngModel)]="filterValue" matInput (keyup)="applyFilter()">
  </mat-form-field>

  <div class="mat-elevation-z8" *ngIf="!updateRentPage">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="id">
        <th class="id-col" mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
        <td class="id-col" mat-cell *matCellDef="let renter"> {{renter.id}} </td>
      </ng-container>

      <ng-container matColumnDef="rent_start">
        <th class="name-col" mat-header-cell *matHeaderCellDef mat-sort-header> Foglalás kezdete</th>
        <td class="name-col" mat-cell *matCellDef="let renter"> {{renter.rent_start}} </td>
      </ng-container>

      <ng-container matColumnDef="rent_end">
        <th class="phone-col" mat-header-cell *matHeaderCellDef mat-sort-header> Foglalás vége</th>
        <td class="phone-col" mat-cell *matCellDef="let renter"> {{renter.rent_end}} </td>
      </ng-container>

      <ng-container matColumnDef="renter_id">
        <th class="mail-col" mat-header-cell *matHeaderCellDef mat-sort-header> Bérlő</th>
        <td class="mail-col" mat-cell *matCellDef="let renter"> {{renter.renter.name + ' (' + renter.renter.id_number + ')'}}</td>
      </ng-container>

      <ng-container matColumnDef="deposit">
        <th class="mail-col" mat-header-cell *matHeaderCellDef mat-sort-header> Kaució</th>
        <td class="mail-col" mat-cell *matCellDef="let renter"> {{renter.deposit}}</td>
      </ng-container>

      <ng-container matColumnDef="car_plate">
        <th class="mother-name-col" mat-header-cell *matHeaderCellDef mat-sort-header> Bérelt autó rendszáma</th>
        <td class="mother-name-col" mat-cell *matCellDef="let renter"> {{renter.car_plate}}</td>
      </ng-container>

      <ng-container matColumnDef="kmh_state_before">
        <th class="id-number-col" mat-header-cell *matHeaderCellDef mat-sort-header> KM óra állás bérlés előtt</th>
        <td class="id-number-col" mat-cell *matCellDef="let renter"> {{renter.kmh_state_before}}</td>
      </ng-container>

      <ng-container matColumnDef="kmh_state_after">
        <th class="license-col" mat-header-cell *matHeaderCellDef mat-sort-header> KM óra állás bérlés után</th>
        <td class="license-col" mat-cell *matCellDef="let renter"> {{renter.kmh_state_after}}</td>
      </ng-container>

      <ng-container matColumnDef="takeaway_start">
        <th class="license-number-col" mat-header-cell *matHeaderCellDef mat-sort-header> Telephelyről elvitte</th>
        <td class="license-number-col" mat-cell *matCellDef="let renter"> {{renter.takeaway_start}}</td>
      </ng-container>

      <ng-container matColumnDef="takeaway_end">
        <th class="nation-col" mat-header-cell *matHeaderCellDef mat-sort-header> Telephelyre visszahozta</th>
        <td class="nation-col" mat-cell *matCellDef="let renter"> {{renter.takeaway_end}}</td>
      </ng-container>

      <ng-container matColumnDef="note">
        <th class="nation-col" mat-header-cell *matHeaderCellDef mat-sort-header> Megjegyzés</th>
        <td class="nation-col" mat-cell *matCellDef="let renter"> {{renter.note}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr (click)="updateRent(renter)" mat-row *matRowDef="let renter; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Keresés eredménytelen az alábbi szűrővel "{{filterValue}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  </div>

  <app-add-rent [rent]="rentToUpdate" [update]="true" *ngIf="updateRentPage"></app-add-rent>

</div>
