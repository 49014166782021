<div class="new-model-component form-component">
  <h1>Beállítások</h1>

  <mat-form-field class="form-input" appearance="fill">
    <mat-label>{{carServiceKmTitle}}</mat-label>
    <input [(ngModel)]="carServiceKm" matInput>
  </mat-form-field>

  <div class="spacer"></div>

  <mat-form-field class="form-input" appearance="fill">
    <mat-label>{{carDocumentExpirationTitle}}</mat-label>
    <input [(ngModel)]="carDocumentExpiration" matInput>
  </mat-form-field>

  <div class="spacer"></div>

  <mat-form-field class="form-input" appearance="fill">
    <mat-label> Korábbi jelszó</mat-label>
    <input [(ngModel)]="oldPassword" type="password"  matInput>
  </mat-form-field>

  <div class="spacer"></div>

  <mat-form-field class="form-input" appearance="fill">
    <mat-label> Új jelszó</mat-label>
    <input [(ngModel)]="newPassword" type="password"  matInput>
  </mat-form-field>

  <div class="spacer"></div>

  <mat-form-field class="form-input" appearance="fill">
    <mat-label> Új jelszó ismétlése</mat-label>
    <input [(ngModel)]="newPasswordConfirm" type="password"  matInput>
  </mat-form-field>

  <div class="spacer"></div>

  <button mat-raised-button (click)="updateSettings()">Beállítások mentése</button>


</div>
