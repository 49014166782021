export class Car {
  public category_size: ['kis', 'közép', 'egyterű', 'mikrobusz', 'teherautó'];
  public active: boolean;
  public plate: string;
  public image: string;
  public fuel: ['Benzin', 'Dízel', 'Hibrid', 'Elektromos'];
  public brand: string;
  public service_interval: number;
  public vignette_expiration: string;
  public service_date: any;
  public car_document_expiration: string;
  public kmh_state: number;
  public deleted: number;
  public note: string;

  constructor() {
  }
}
