import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {PersistanceService} from '../persistance.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  selectedMenu = '';
  warnings = [];
  warningsNotification = 0;

  constructor(
    public service: AppService,
    public persistanceService: PersistanceService) {
  }

  ngOnInit(): void {
    this.getWarnings();
    setInterval(() => this.getWarnings(), 300000);

    $('[data-widget="treeview"]').Treeview('init');
  }

  logout(): void {
    this.persistanceService.remove('token');
  }

  getWarnings(): void {
    this.warnings = [];
    this.service.getWarnings().subscribe(req => {
      for (const [key, value] of Object.entries(req.data)) {
        this.warnings.push(value);
      }
      this.warningsNotification = this.warnings.length;
    });
  }

}
